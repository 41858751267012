<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'The Entrance Data' | translate">
  </legend>
  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-dropdown
        formControlName="entryPort"
        identifier="entryPort"
        key="trImportEntryPortFld"
        name="entryPort"
        [items]="lookups.entryPorts"
        [bindLabel]="defaultLookupDisplayProperty"
        [templateOptions]="fo.inputsTemplateOptions"
      ></bs-ng-dropdown>
    </div>
    <div *ngIf="formGroup.controls.countryDeclaration"
          dsCol [colOptions]="fo.fullCol">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="countryDeclaration"
          class="custom-control-input"
          formControlName="countryDeclaration"
          [class.is-invalid]="
            formGroup.controls.countryDeclaration.touched && 
            formGroup.controls.countryDeclaration.errors"
        />
        <label class="custom-control-label" for="countryDeclaration">
          <span>{{"trCitesImportDeclarationTxt.labelText" | translate}}
            <a [href]="transaction.countryDeclarationURL" target="_blank">{{"ClickHere" | translate}}</a>
          </span>
        </label>
      </div>
    </div>
  </div>
</form>