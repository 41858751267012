<div class="custom-control custom-checkbox">
  <input
    [id]="identifier"
    [name]="name"
    type="checkbox"
    class="custom-control-input"
    [ngClass]="
      hasRequiredError() || hasEqualTrueError()
        ? 'is-invalid'
        : touched
        ? 'is-valid'
        : ''
    "
    [(ngModel)]="value"
    [value]="set"
    [checked]="value == set"
    [placeholder]="placeholder"
    #control="ngModel"
    [required]="required == 'true' || required == '' ? '' : undefined"
    (blur)="onTouched()"
    [readonly]="readonly"
    [disabled]="disabled"
  />
  <label class="custom-control-label" [for]="identifier">
    <span [innerText]="labelText"></span>
    <span
      *ngIf="hasRequiredValidator()"
      class="custom-checkbox-required-start"
      [innerText]="'*'"
    ></span>
  </label>

  <label *ngIf="htmlLabel" class="custom-control-label" [for]="identifier">
    <span [innerHTML]="evaluatedHtmlLabel"></span>
    <span
      *ngIf="hasRequiredValidator()"
      class="custom-checkbox-required-start"
      [innerText]="'*'"
    ></span>
  </label>

  <small
    [id]="identifier + 'Help'"
    *ngIf="helpText"
    class="form-text text-muted"
    [innerText]="helpText"
  ></small>
  <div
    class="invalid-feedback"
    *ngIf="hasRequiredError() || hasEqualTrueError()"
  >
    {{ requiredMessage }}
  </div>
</div>
