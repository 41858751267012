<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div class="form-group">
    <label>
      {{ (key | translate).labelText }}
      <a
        *ngIf="infoDialogId"
        class="gold cursor-pointer"
        data-toggle="modal"
        [attr.data-target]="'#' + infoDialogId"
        [title]="(key | translate).infoMessage"
      >
        <i class="fi-info"></i>
      </a>
    </label>
    <ng-container
      [ngTemplateOutlet]="showValueText ? normalText : translated"
    ></ng-container>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <div class="col-sm-3">
      <label>
        {{ (key | translate).labelText }}
        <a
          *ngIf="infoDialogId"
          class="gold cursor-pointer"
          data-toggle="modal"
          [attr.data-target]="'#' + infoDialogId"
          [title]="(key | translate).infoMessage"
        >
          <i class="fi-info"></i>
        </a>
      </label>
    </div>
    <div class="col-sm-9">
      <ng-container
        [ngTemplateOutlet]="showValueText ? normalText : translated"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #normalText>
  <div class="detail-text">{{ valueText }}</div>
</ng-template>

<ng-template #translated>
  <div
    class="detail-text"
    [innerText]="isArabic ? valueTextAr : valueTextEn"
  ></div>
</ng-template>
