import {
  EsAttachment,
  EsComment,
  EsReceipt,
} from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { EsApplicant } from './EsApplicant';
import { EsOutputs } from './EsOutputs';
import { RequestPurposeLkp } from './Lookups';
import { ParticipantTemplate } from '../../api-services/export/shared/template-services/TemplateServicesResponse';
import { RequestAvailabilityEnum } from './ServiceRequestDto';

export abstract class EsBaseServiceRequest {
  applicant?: EsApplicant;
  receipts?: EsReceipt[];
  comments?: EsComment[];
  attachments?: EsAttachment[];
  outputs?: EsOutputs[];
  availability?: RequestAvailabilityEnum;
  isValidForRelease?: boolean;
  releaseURL?: string;

  serviceRequestTransactionData?: any;
  serviceTransaction?: any;
  requestPurpose?: RequestPurposeLkp;

  template?: ParticipantTemplate;
}
