import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../utilities/util.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { RedirectService } from 'src/app/shared/authentication/redirect.service';
import {
  ResponseModel,
  ToastMessageType,
} from '../models/shared/CommonResponseDTO';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private utilService: UtilService,
    public auth: AuthService,
    public redirectService: RedirectService,
    private translateService: TranslateService
  ) {}

  removeRequest(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    // to show the loader in the fast response.
    setTimeout(
      () => this.loaderService.isLoading.next(this.requests.length > 0),
      200
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.loaderService.isLoading.next(true);
    // tslint:disable-next-line: deprecation
    return Observable.create(
      (observer: {
        next: (arg0: HttpResponse<any>) => void;
        error: (arg0: any) => void;
        complete: () => void;
      }) => {
        const subscription = next.handle(req).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          (err) => {
            if (environment.production) {
              if (this.auth.isAuthenticated()) {
                this.showGenericError(err);
              } else {
                const encodedLocation = encodeURIComponent(
                  window.location.href
                );

                const loginUrl = `${environment.loginURL}?retUrl=${encodedLocation}`;

                this.redirectService
                  .redirect(loginUrl, '_self')
                  .then(() => false);
              }
            }

            if (!environment.production) {
              this.showGenericError(err);
            }

            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          }
        );
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      }
    );
  }

  showGenericError(err: any): void {
    if(err.url == environment.ipInfoUrl)//don't show error for ip info call even if failed.
      return;
    if (err.error && err.error.errorResponse) {
      const responseError = err.error as ResponseModel<any>;

      let errorMessage = responseError.errorResponse.errorMessageEn;

      if (this.translateService.currentLang === 'ar') {
        errorMessage = responseError.errorResponse.errorMessageAr;
      }
      switch (responseError.errorResponse.messageType) {
        case ToastMessageType.Error:
          this.toastr.error(errorMessage);
          break;
        case ToastMessageType.Info:
          this.toastr.info(errorMessage);
          break;
        case ToastMessageType.Success:
          this.toastr.success(errorMessage);
          break;
        case ToastMessageType.Warning:
          this.toastr.warning(errorMessage);
          break;
      }
    } else {
      if (err.error && err.error.Message) {
        let messageKey = `trEServiceErrors.${err.error.Message}`;

        if (
          err.error.Data?.IsDataValid == false &&
          err.error.Data.Validations?.length
        ) {
          if (
            this.hasTranslation(
              `trEServiceErrors.${err.error.Data.Validations[0].Value}`
            )
          )
            messageKey = `trEServiceErrors.${err.error.Data.Validations[0].Value}`;
        }

        let message = this.errorOrGeneric(messageKey);

        this.toastr.error(message, '', {
          timeOut: 12000,
        });

        return;
      }

      this.translateService.get('GenericError').subscribe((res) => {
        this.toastr.error(res, '', {
          timeOut: 12000,
        });
      });
    }
  }

  private errorOrGeneric(messageKey: string): string {
    if (this.hasTranslation(messageKey)) {
      return this.translateService.instant(messageKey);
    }

    if (messageKey.indexOf('Error:') >= 0) {
      ///The message is not a key, it is an exception message from EServices business.
      return messageKey;
    }

    return this.translateService.instant('trEServiceErrors.GeneralError');
  }

  private hasTranslation(messageKey: string): boolean {
    let message = this.translateService.instant(messageKey);

    return message != messageKey;
  }
}
