import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { valueFromItemsOnly } from '../../../validators/valueFromItemsOnly.validator';
import { InputViewModeEnum } from './InputViewModeEnum';

@Component({
  selector: 'bs-ng-dropdown',
  templateUrl: './bs-ng-dropdown.component.html',
  providers: [MakeProvider(BsNgDropdownComponent)],
})
export class BsNgDropdownComponent
  extends BsNgInputBaseComponent
  implements OnInit, AfterViewInit
{
  private _items: any[] = [];
  @Input()
  set items(value: any[]) {
    this._items = value;

    if (this.selectFromItemsOnly) {
      this.ngControl.control.updateValueAndValidity();
    }
  }
  get items(): any[] {
    return this._items;
  }

  @Input()
  searchable: boolean = true;

  @Input()
  bindLabel: string = '';

  @Input()
  bindValue: string = 'id';

  @Input()
  addTag = false;

  @Input()
  addTagText: string = 'add item';

  @Input() equalValidationMessage: string;

  @Input()
  valueFromItemsOnlyMessage: string;

  @Input() sortProp: any = { ar: 'nameAr', en: 'nameEn', dir: true };

  @Input() breakSpaces = false;

  @Input() multiple = false;
  @Input() closeOnSelect = true;

  @Input()
  selectFromItemsOnly = false;

  @Input()
  viewMode: InputViewModeEnum = InputViewModeEnum.Input;

  @Input()
  displayDefaultValue: any = null;

  @Input()
  selectFromItemsCompareWith: CompareWithFn;

  @Input()
  infoDialogId?: string = null;

  label: any = { ar: 'nameAr', en: 'nameEn' };

  dropdownLabel: string = '';

  forbiddenMessage = '';

  @ViewChild(NgSelectComponent)
  ngSelect!: NgSelectComponent;

  isArabic: boolean;

  @Output()
  search: any = new EventEmitter();
  validator: any;

  public get isDisplay(): boolean {
    return this.viewMode == InputViewModeEnum.Display;
  }

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(translate, injector);
    this.isArabic = translate.currentLang == 'ar';

    /// on language change need to call detectChange on the ng-select, as it uses the push change detection method
    /// https://github.com/ng-select/ng-select#change-detection
    translate.onLangChange.subscribe(() => {
      this.items = [...this.items];
      this.isArabic = translate.currentLang == 'ar';
      this.ngSelect.detectChanges();
    });
  }

  ngOnInit() {
    this.dropdownLabel = `${this.identifier}-dropdown`;

    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit() {
    this.assignValueFromItemsOnlyValidation();
  }

  public searchFunction($event: any): void {
    this.search.emit($event);
  }

  private assignValueFromItemsOnlyValidation() {
    if (this.selectFromItemsOnly) {
      this.ngControl.control.addValidators(
        valueFromItemsOnly(
          () => this.items,
          (i1, i2) => this.selectFromItemsCompareWith(i1, i2)
        )
      );
      this.ngControl.control.updateValueAndValidity();
    }
  }

  hasForbidden(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.forbidden
    );
  }

  protected assignTranslations(trs: any) {
    super.assignTranslations(trs);

    this.forbiddenMessage = trs.forbiddenMessage || '';
    this.equalValidationMessage = trs.equalValidationMessage;
    this.valueFromItemsOnlyMessage =
      trs.valueFromItemsOnlyMessage || trs.requiredMessage || '';
  }

  hasEqualValidationError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      !this.ngControl.control.errors.required &&
      this.ngControl.control.errors.equal
    );
  }

  public open() {
    if (this.breakSpaces) {
      setTimeout(() => {
        const panel = this.document.querySelector('.ng-dropdown-panel');
        panel.classList.add('breakSpaces');
      });
    }
  }

  public get hasValueFromItemsOnlyError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.valueFromItems
    );
  }
}
