import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app.module';
import {
  ColOptions,
  ColSpace,
  ScreenMediaSize,
} from 'src/app/services/es-shared/ds-col/ds-col.component';
import { FormOptions } from 'src/app/services/es-shared/ds-col/FormOptions';
import { InputTemplateOptions } from '../input-components/components/bs-ng-components';
import {
  FormTemplateEnum,
  SettingsService,
} from '../settings/settings.service';
import { FlowStepBaseComponent } from './FlowStepBaseComponent';

@Component({
  template: '',
})
export abstract class RequestBaseComponent extends FlowStepBaseComponent {
  form: FormGroup;
  @ViewChild('mainFormDir') mainFormDir: FormGroupDirective;

  settingsService: SettingsService;

  constructor(translateService: TranslateService) {
    super(translateService);

    this.settingsService = AppInjector.get(SettingsService);
  }

  protected subscribeToFormsTemplatesSettings() {
    this.settingsService.setSupportFormsTemplates(true);
    this.settingsService.formsTemplate$.subscribe((t) => {
      this.changeHalfSize(t.template);
      this.toggleFormDirection(t.template);
    });
  }

  private halfCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Half,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  private displayHalfCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Half,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  private fullCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  private inputsTemplateOptions: InputTemplateOptions = {
    direction: 'default',
  };

  public get formOptions(): FormOptions {
    return {
      halfCol: this.halfCol,
      fullCol: this.fullCol,
      inputsTemplateOptions: this.inputsTemplateOptions,

      displayHalfCol: this.displayHalfCol,
    };
  }

  private changeHalfSize(formTemplate: FormTemplateEnum): void {
    if (formTemplate == FormTemplateEnum.Default) {
      this.halfCol.maximumSize.colSpace = ColSpace.Half;
      return;
    }

    if (formTemplate == FormTemplateEnum.Horizontal) {
      this.halfCol.maximumSize.colSpace = ColSpace.Full;
      return;
    }
  }

  private toggleFormDirection(formTemplate: FormTemplateEnum): void {
    this.inputsTemplateOptions = {
      direction:
        formTemplate == FormTemplateEnum.Default ? 'default' : 'horizontal',
    };
  }
}
