import { LookupDto } from 'src/app/core/models/shared/LookupDto';
import {
  ILookup,
  PharmaceuticalFormLkp,
  RequestPurposeLkp,
} from 'src/app/core/models/shared/Lookups';
import { ResponseData } from 'src/app/core/models/portal/ServiceDetailByDigitalNumberResponse';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { GetServiceConfigurationResponse } from 'src/app/core/api-services/shared/GetServiceDetailsResponse';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { EsApplicant } from 'src/app/core/models/shared/EsApplicant';
import { LicenseDetails } from 'src/app/core/models/shared/LicenseDetails';
import { EsAttachmentDetailDto } from 'src/app/core/models/shared/EsAttachmentDetailDto';
import { EsAttachmentFileDto } from 'src/app/core/models/shared/EsAttachmentFileDto';
import {
  EsAttachmentsNames,
  EsAttachment,
  EsComment,
  EsReceipt,
  ServiceRequestTransactionEsData,
  EsFee,
} from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { EsRequestStatusCodesEnum } from 'src/app/core/models/shared/MainWorkflowStepStatusCodesEnum';
import { DisclaimerDto } from 'src/app/core/models/shared/DisclaimerDto';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { UserDetailsDto } from 'src/app/core/models/shared/UserDetailsDto';
import { UserDetails } from 'src/app/core/models/shared/UserDetails';
import { EsUserAccessibilityToService } from 'src/app/core/models/shared/EsUserAccessibilityToService';
import { EsCustomerPulseDetails } from 'src/app/core/models/shared/EsCustomerPulseDetails';
import { ServiceRequestDto } from 'src/app/core/models/shared/ServiceRequestDto';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';
import { TypeLookupListResponse } from 'src/app/core/api-services/export/shared/responses/TypeLookupListResponse';
import { EsApplicantDto } from 'src/app/core/models/shared/EsApplicantDto';
import { RequestPurposeTypeEnum } from 'src/app/core/enums/RequestPurposeTypeEnum';
import { EsServiceAttachments } from 'src/app/core/models/shared/EsServiceAttachments';
import { DatePipe } from '@angular/common';
import { EsOutputs } from 'src/app/core/models/shared/EsOutputs';
import {
  EsFeeDto,
  EsReceiptDto,
} from 'src/app/core/models/shared/EsReceiptDto';
import {
  AttachmentsNamesDto,
  EsCommentDto,
} from 'src/app/core/models/shared/EsCommentDto';
import { EsPaymentFee } from 'src/app/core/models/shared/EsPaymentFee';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import { OutPutsDto } from 'src/app/core/models/shared/OutPutsDto';
import { PaymentFeeDto } from 'src/app/core/models/shared/PaymentFeeDto';
import { ProcedureInstanceObjectDto } from 'src/app/core/models/shared/ProcedureInstanceObjectDto';
import { EsFeesDto } from 'src/app/core/models/shared/InitiateRequestResponseDto';
import { UserAccessibilityToServiceDto } from 'src/app/core/models/shared/UserAccessibilityToServiceDto';
import { LicenseDetailsDto } from 'src/app/core/models/shared/LicenseDetailsDto';
import { ServiceAttachmentsDto } from 'src/app/core/models/shared/ServiceAttachmentsDto';
import { LookupListResponse } from 'src/app/core/api-services/shared/LookupListResponse';
import { YesNoLkp } from 'src/app/core/models/common/Lookup';
import { EsOtpApplicant } from 'src/app/core/models/shared/EsOtpApplicant';
import { BaseMapper } from './BaseMapper';
import { GetDraftRequestDto } from 'src/app/core/api-services/shared/save-as-draft/SaveAsDraftRequest';
import { ParticipantTemplateDto } from 'src/app/core/api-services/export/shared/template-services/TemplateServicesResponse';

export abstract class ServiceBaseMapper extends BaseMapper {
  public abstract mapGetServiceConfigurationResponse(
    serviceConfigResponse: GetServiceConfigurationResponse
  ): EsServiceRequestConfiguration;

  protected fromResponseToYesNoLookup(value: boolean): YesNoLkp {
    if (value == true || value == false) {
      return value == true ? this.yesLkp : this.noLkp;
    }

    return null;
  }

  protected mapStaticYesNo(): YesNoLkp[] {
    return [this.noLkp, this.yesLkp];
  }

  constructor(datePipe: DatePipe) {
    super(datePipe);
  }

  public mapApplicant(applicantDto: EsApplicantDto): EsApplicant {
    let result: EsApplicant = new EsApplicant();

    result.email = applicantDto.Email;
    result.id = applicantDto.ID;
    result.identityNumber = applicantDto.IdentityNumber;
    result.identityType = applicantDto.IdentityType;
    result.name = applicantDto.Name || applicantDto.NameAr;
    result.participantId = applicantDto.ParticipantID;
    result.passport = applicantDto.Passport;
    result.phone = applicantDto.Phone;
    result.preferredLanguage = applicantDto.PreferedLanguage;

    result.countryId = applicantDto.CountryId;
    result.affiliation = applicantDto.Affiliation;
    result.address = applicantDto.Address;
    result.birthDate = this.convertDateStringToDate(applicantDto.BirthDate);
    result.emirateId = applicantDto.EmirateId;
    result.idStatus = applicantDto.IDStatus;

    return result;
  }

  protected mapLookup(lookupArray: LookupDto[]): ILookup[] {
    if (lookupArray == null || lookupArray == undefined) {
      console.warn('lookupArray parameter can not be null or undefined.');

      lookupArray = lookupArray || [];
    }

    return lookupArray.map((i) => {
      return {
        nameAr: i.NameAr,
        nameEn: i.NameEn,
        nameUr: i.NameUr,
        id: i.Id,
        code: i.Code,
        parentId: i.ParentID,
        inspectionTemplateFileId:i.InspectionTemplateFileId
      };
    });
  }

  protected getMetadataByLanguage(
    responseData: ResponseData[],
    language: string,
    requestTypeCode: string
  ): ResponseData {
    return responseData.filter(
      (responseItem) => responseItem.language == language
    )[0];
  }

  public mapMetadata(
    responseData: ResponseData[],
    requestTypeCode: string
  ): ServiceMetaData {
    const arabic = 'Arabic';
    const english = 'English';

    const metadataEn = this.getMetadataByLanguage(
      responseData,
      english,
      requestTypeCode
    );
    const metadataAr =
      this.getMetadataByLanguage(responseData, arabic, requestTypeCode) ??
      metadataEn;

    const metadata: ServiceMetaData = {
      nameAr: metadataAr?.title,
      nameEn: metadataEn?.title,
      formattedTermsAndConditionsAr: metadataAr?.termsAndConditions,
      formattedTermsAndConditionsEn: metadataEn?.termsAndConditions,
      portalLinkAr: metadataAr?.link,
      portalLinkEn: metadataEn?.link,
      termsAndConditionsAr: metadataAr?.termsAndConditions,
      termsAndConditionsEn: metadataEn?.termsAndConditions,
      youTubeVideoURLAr: metadataAr?.webYoutubeLink,
      youTubeVideoURLEn: metadataEn?.webYoutubeLink,
      faqAr: metadataAr?.serviceFAQ,
      faqEn: metadataEn?.serviceFAQ,
      digitalNumber: metadataEn?.digitalNumber,
    };

    return metadata;
  }

  public mapApplicantDto(
    applicant: EsApplicant,
    timeTakenByCustomer: number
  ): EsApplicantDto {
    const result: EsApplicantDto = {
      ID: applicant.id,
      Email: applicant.email,
      IdentityNumber: applicant.identityNumber,
      IdentityType: applicant.identityType,
      Name: applicant.name,
      NameAr: applicant.nameAr,
      Passport: applicant.passport,
      Phone: applicant.phone,
      PreferedLanguage: applicant.preferredLanguage,
      ParticipantID: applicant.participantId,
      TimeTakenByCustomer: timeTakenByCustomer,
    };

    return result;
  }

  public mapAttachmentsInstances(
    attachments: EsAttachmentDetailDto[]
  ): EsAttachmentFileDto[] {
    return attachments.map((a) => {
      const result: EsAttachmentFileDto = {
        ProcedureAttachmentId: a.ProcedureAttachmentId,
        AttchfileNames: a.AttachmentFileName,
        FileId: a.Path,
      };

      return result;
    });
  }

  protected mapGetServiceRequestDetailsResponse(
    procedureInstanceObjectDto: ProcedureInstanceObjectDto
  ): ServiceRequestTransactionEsData {
    let result = new ServiceRequestTransactionEsData();

    result.id = procedureInstanceObjectDto.ProcedureInstanceId;
    result.serviceRequestNumber =
      procedureInstanceObjectDto.ProcedureInstanceNumber;
    result.createdBy = procedureInstanceObjectDto.CreatedBy;
    result.createdDate = procedureInstanceObjectDto.CreationDate;
    result.statusCode = this.mapStatusCodeFrom(
      procedureInstanceObjectDto.StatusCode
    );
    result.statusAr = procedureInstanceObjectDto.StatusAr;
    result.statusEn = procedureInstanceObjectDto.StatusEn;
    result.requestPurposeId = procedureInstanceObjectDto.RequestPurposeId;
    result.isFeedbackDone = procedureInstanceObjectDto.IsFeedbackDone;

    return result;
  }

  mapStatusCodeFrom(statusCode: string): EsRequestStatusCodesEnum {
    return (<any>EsRequestStatusCodesEnum)[statusCode];
  }

  public mapBasic(dto: ServiceRequestDto<any>): EsBaseServiceRequest {
    let result: EsBaseServiceRequest = {
      applicant: this.mapApplicant(dto.Applicant),
      attachments: this.mapAttachments(dto.AttachmentDetails),
      receipts: this.mapReceipts(dto.Receipts),
      comments: this.mapComments(dto.Comments),
      outputs: this.mapOutputs(dto.OutPuts),
      availability: dto.RequestAvailabilityStatus,
      isValidForRelease: dto.IsValidForRelease,
      releaseURL: dto.ReleaseServiceURl,
      requestPurpose: this.mapRequestPurposeId(dto.ProcedureInstanceObject),
    };

    return result;
  }

  private mapOutputs(outputs: OutPutsDto[]): EsOutputs[] {
    return outputs.map((o) => {
      let result: EsOutputs = {
        certificateNumber: o.CertificateNumber,
        colorKey: o.ColorKey,
        titleAr: o.TitleAr,
        titleEn: o.TitleEn,
        url: o.URL,
      };

      return result;
    });
  }

  private mapReceipts(receipts: EsReceiptDto[]): EsReceipt[] {
    if (receipts) {
      return receipts.map((r: EsReceiptDto) => {
        let mapped: EsReceipt = {};

        mapped.amount = r.Amount;
        mapped.creationDate = r.CreationDate;
        mapped.eDirhamFees = r.EDirhamFees;
        mapped.paymentType = this.fromResponseToLookup(
          r.PaymentType.Id,
          r.PaymentType.NameAr,
          r.PaymentType.NameEn,
          r.PaymentType.Code
        );
        mapped.receiptDate = r.ReceiptDate;
        mapped.receiptId = r.ReceiptId;
        mapped.receiptNumber = r.ReceiptNumber;
        mapped.remarks = r.Remarks;
        mapped.fees = this.mapReceiptFees(r.Fees);

        return mapped;
      });
    }

    return undefined;
  }

  private mapReceiptFees(feesDto: EsFeeDto[]): EsFee[] {
    if (feesDto) {
      return feesDto.map((f) => {
        const result: EsFee = {};

        result.certificateTypeId = f.CertificateTypeId;

        result.id = f.Id;
        result.nameEn = f.NameEn;
        result.nameAr = f.NameAr;
        result.nameUr = f.NameUr;
        result.code = f.Code;
        result.percentage = f.Percentage;
        result.minValue = f.MinValue;
        result.maxValue = f.MaxValue;
        result.operator = f.Operator;
        result.value1 = f.Value1;
        result.value2 = f.Value2;
        result.variableId1 = f.VariableId1;
        result.variableId2 = f.VariableId2;
        result.feeTotal = f.FeeTotal;
        result.total = f.Total;
        result.lastModifiedBy = f.LastModifiedBy;
        result.lastModified = f.LastModified;
        result.quantity = f.Quantity;
        result.isRepeated = f.IsRepeated;
        result.onceAtShipment = f.OnceAtShippment;
        result.certificateTypeId = f.CertificateTypeId;
        result.feeAmount = f.FeeAmount;
        result.isVariableFee = f.IsVariableFee;

        return result;
      });
    }

    return [];
  }

  private mapAttachments(
    attachmentDetails: EsAttachmentDetailDto[]
  ): EsAttachment[] {
    return attachmentDetails.map((a) => {
      const attachment: EsAttachment = {};

      attachment.attachmentCode = a.AttachmentCode;
      attachment.attachmentFileName = a.AttachmentFileName;
      attachment.attachmentLocalizedName = a.AttachmentLocalizedName;
      attachment.attachmentNameAr = a.AttachmentNameAr;
      attachment.attachmentNameEn = a.AttachmentNameEn;
      attachment.attachmentNameUr = a.AttachmentNameUr;
      attachment.createdBy = a.CreatedBy;
      attachment.creationDate = a.CreationDate;
      attachment.fileName = a.FileName;
      attachment.isRequired = a.IsRequired;
      attachment.lastModificationDate = a.LastModificationDate;
      attachment.lastModifiedBy = a.LastModifiedBy;
      attachment.loadFromHistory = a.LoadFromHistory;
      attachment.path = a.Path;
      attachment.procInstAttchId = a.ProcInstAttchId;
      attachment.procedureAttachmentId = a.ProcedureAttachmentId;
      attachment.procedureInstanceId = a.ProcedureInstanceId;

      return attachment;
    });
  }

  protected fromResponseToLookup(
    id: number,
    nameAr: string,
    nameEn: string,
    code: string = undefined,
    nameUr?: string
  ): ILookup {
    return { nameAr, nameEn, id, code, nameUr: nameUr };
  }

  protected fromResponseToLookupOrNull(
    id: number,
    nameAr: string,
    nameEn: string,
    code: string = undefined,
    nameUr?: string,
    defaultCriteria?: Function
  ): ILookup {
    const mapped = this.fromResponseToLookup(id, nameAr, nameEn, code, nameUr);

    if (defaultCriteria && defaultCriteria(mapped)) {
      return null;
    }

    return mapped;
  }

  private mapComments(comments: EsCommentDto[]): EsComment[] {
    return comments.map((c) => {
      const mapped: EsComment = {};

      mapped.attachments = this.mapAttachmentNames(c.Attachments);
      mapped.commentBody = c.CommentBody;
      mapped.commentSource = c.CommentSource;
      mapped.commentSourceAr = c.CommentSourceAr;
      mapped.creationDate = c.CreationDate;
      mapped.procedureInstanceId = c.ProcedureInstanceId;

      return mapped;
    });
  }

  private mapAttachmentNames(
    attachmentNamesDto: AttachmentsNamesDto[]
  ): EsAttachmentsNames[] {
    return attachmentNamesDto?.map((dto) => {
      let result: EsAttachmentsNames = {
        name: dto.Name,
        guid: dto.Value,
      };

      return result;
    });
  }

  protected mapUserDetails(userDetailsDto: UserDetailsDto): UserDetails {
    let result = new UserDetails();

    if (userDetailsDto) {
      result = {
        nameAr: userDetailsDto.Name,
        nameEn: userDetailsDto.NameEn,
        city: userDetailsDto.City,
        mobile: userDetailsDto.Mobile,
        email: userDetailsDto.Email,
        fax: userDetailsDto.Fax,
        poBox: userDetailsDto.PObox,
        address: userDetailsDto.Address,
        nationality: this.toLookup(userDetailsDto.Nationality),
        accountNumber: userDetailsDto.AccountNumber,
        participantType: userDetailsDto.ParticipantType
      };
    } else {
      /// UserData holds user details and used in selected service.
      console.warn(
        `UserData doesn't have value, the absence of UserData might affect some services`
      );
    }

    return result;
  }

  private mapLicenseDetails(licenseDetails: LicenseDetailsDto): LicenseDetails {
    if (licenseDetails) {
      return {
        showLicenseDetails: licenseDetails.ShowlicenceDetailsSection,
        certificateNumber: licenseDetails.CertificateNumber,
        certificateTypeName: licenseDetails.CertificateTypeName,
        certificateTypeNameAr: licenseDetails.CertificateTypeNameAr,
        expiryDate: licenseDetails.ExpiryDate,
        startDate: licenseDetails.StartDate,
      };
    }

    return null;
  }

  protected fromDisclaimers(disclaimers: DisclaimerDto[]): AlertOptions[] {
    if (disclaimers)
      return disclaimers.map((d) => {
        return {
          textAr: d.NameAr,
          textEn: d.NameEn,
          display: true,
          code: d.Code,
        };
      });

    return [];
  }

  protected mapBasicServiceConfigurationResponse(
    response: GetServiceConfigurationResponse
  ): EsServiceRequestConfiguration {
    const config = new EsServiceRequestConfiguration();
    const data = response.Data;

    config.procedureId = data.ProcedureId;

    config.accessibility = this.mapUserServiceAccessibility(
      data.UserAccessibilityToService
    );

    config.licenseDetails = this.mapLicenseDetails(data.LicenseDetails);
    config.alerts = this.fromDisclaimers(data.Disclaimers);
    config.applicants = this.mapApplicantLookupDto(data.Applicants);
    config.userDetails = this.mapUserDetails(data.UserData);
    config.isDraftAllowed = data.IsAllowDraft;

    return config;
  }

  private mapApplicantLookupDto(
    applicantsDto: EsApplicantDto[]
  ): EsApplicant[] {
    if (applicantsDto == null) {
      return [];
    }

    return applicantsDto.map((a) => {
      const applicant = new EsApplicant();

      applicant.email = a.Email;
      applicant.id = a.ID;
      applicant.identityNumber = a.IdentityNumber;
      applicant.identityType = a.IdentityType;
      applicant.name = a.Name;
      applicant.nameAr = a.NameAr;
      applicant.participantId = a.ParticipantID;
      applicant.passport = a.Passport;
      applicant.phone = a.Phone;
      applicant.preferredLanguage = a.PreferedLanguage;

      return applicant;
    });
  }

  public mapUserServiceAccessibility(
    dto: UserAccessibilityToServiceDto
  ): EsUserAccessibilityToService {
    let result: EsUserAccessibilityToService = {
      isUserAllowedToUseService: dto.IsUserAllowedToUseService,
      reasonAr: dto.ReasonAr,
      reasonEn: dto.ReasonEn,
      url: dto.URL,
      userHasSpecialTypes: dto.UserHasSpecialTypes,
    };

    return result;
  }

  public mapFees(feesDto: EsFeesDto): EsFees {
    const result = new EsFees();

    if (feesDto) {
      result.total = feesDto.Total;
      result.feeGrid = this.mapFeeGrid(feesDto.FeeGrid);
    }

    return result;
  }

  private mapFeeGrid(feeGrid: PaymentFeeDto[]): EsPaymentFee[] {
    return feeGrid.map((f) => {
      const result = new EsPaymentFee();
      result.code = f.Code;
      result.feeAmount = f.FeeAmount;
      result.feeTotal = f.FeeTotal;
      result.id = f.Id;
      result.nameAr = f.NameAr;
      result.nameEn = f.NameEn;
      result.quantity = f.Quantity;

      return result;
    });
  }

  public mapRequestPurposeId(
    ProcedureInstanceObject: ProcedureInstanceObjectDto
  ): RequestPurposeLkp {
    return this.mapRequestPurpose().find(
      (a) => a.id == ProcedureInstanceObject.RequestPurposeId
    );
  }

  public mapTypeLookupResponse(result: TypeLookupListResponse): ILookup[] {
    return result.Data.map((i) => {
      const result: PharmaceuticalFormLkp = this.fromResponseToLookup(
        i.Id,
        i.NameAr,
        i.NameEn,
        i.Code
      );

      return result;
    });
  }

  public mapLookupResponse(result: LookupListResponse): ILookup[] {
    return result.Data.map((i) => {
      const result: PharmaceuticalFormLkp = this.fromResponseToLookup(
        i.Id,
        i.NameAr,
        i.NameEn,
        i.Code
      );

      return result;
    });
  }

  public mapRequestPurpose(): RequestPurposeLkp[] {
    const research: RequestPurposeLkp = {
      id: 1,
      nameAr: 'بحثي',
      nameEn: 'Research',
      code: RequestPurposeTypeEnum.Research,
    };

    const commercial: RequestPurposeLkp = {
      id: 2,
      nameAr: 'تجاري',
      nameEn: 'Commercial',
      code: RequestPurposeTypeEnum.Commercial,
    };

    return [research, commercial];
  }

  public mapServiceAttachments(
    attachments: ServiceAttachmentsDto[]
  ): EsServiceAttachments[] {
    return attachments.map((a) => {
      return {
        fileName: a.FileName,
        isRequired: a.IsRequired,
        nameAr: a.NameAr,
        nameEn: a.NameEn,
        attachmentCode: a.AttachmentCode,
        procedureAttachmentId: a.ProcedureAttachmentId,
      };
    });
  }

  public toLookups(dto: LookupDto[]): ILookup[] {
    if (dto) {
      return dto.map((d) => this.toLookup(d));
    } else {
      return null;
    }
  }

  public toLookup(dto: LookupDto): ILookup {
    if (dto) {
      const result: ILookup = {
        id: dto.Id,
        nameAr: dto.NameAr,
        nameEn: dto.NameEn,
        code: dto.Code,
        parentId: dto.ParentID,
      };

      return result;
    }

    return null;
  }

  protected lookupToIds(items: ILookup[]): number[] {
    if (items) {
      return items.map((i) => i.id);
    }

    return null;
  }

  public mapOtpApplicant(applicantDto: EsApplicantDto): EsOtpApplicant {
    if (applicantDto) {
      const result: EsOtpApplicant = {
        id: applicantDto.ID,
        participantId: applicantDto.ParticipantID,
        nameEn: applicantDto.Name,
        nameAr: applicantDto.NameAr,
        identityType: applicantDto.IdentityType,
        identityNumber: applicantDto.IdentityNumber,
        passport: applicantDto.Passport,
        email: applicantDto.Email,
        phone: applicantDto.Phone,
        preferredLanguage: applicantDto.PreferedLanguage,
        countryId: applicantDto.CountryId,
        affiliation: applicantDto.Affiliation,
        address: applicantDto.Address,
        birthDate: this.convertDateStringToDate(applicantDto.BirthDate),
        emirateId: applicantDto.EmirateId,
        idStatus: applicantDto.IDStatus,
      };

      return result;
    }

    return null;
  }

  public mapUltimateDraftResponse(
    dto: GetDraftRequestDto
  ): EsBaseServiceRequest {
    let result: EsBaseServiceRequest = {
      applicant: this.mapApplicant(dto.Applicant),
      requestPurpose: this.mapRequestPurposeId(dto.ProcedureInstanceObject),
      attachments: this.mapAttachments(dto.AttachmentDetails),
    };

    result.serviceTransaction = JSON.parse(dto.DraftRequest);
    result.serviceRequestTransactionData =
      this.mapGetServiceRequestDetailsResponse(dto.ProcedureInstanceObject);

    return result;
  }

  public mapTemplateRequestResponse(
    dto: ParticipantTemplateDto
  ): EsBaseServiceRequest {
    let result: EsBaseServiceRequest = {};

    result.serviceTransaction = JSON.parse(dto.Template);

    return result;
  }
}
