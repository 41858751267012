import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FormMode } from 'src/app/shared/base/FormMode';
import { CitesWorkflowBaseService } from '../shared/cites-workflow-base.service';
import {
  lessThanOrEqualDate,
  optionalMoreThanOrEqualDate,
} from 'src/app/shared/input-components/validators/more-than-date.validator';
import { InputPatterns } from 'src/app/shared/input-components/validators/InputPatterns';
import { CitesImportSpeciesStore } from './CitesImportSpeciesStore';
import { CitesImportSpeciesService } from 'src/app/core/api-services/cites/cites-import-species/cites-import-species.service';
import { CitesImportSpeciesMapper } from './CitesImportSpeciesMapper';
import {
  CitesImportSpeciesItem,
  CitesImportSpeciesTransaction,
  CitesImportSpeciesTransactionData,
} from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { GetCitesImportSpeciesResponse } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesResponse';
import { CountryLkp, INRTypeLkp } from 'src/app/core/models/shared/Lookups';
import { Lookup } from 'src/app/core/models/common/Lookup';
import { CitesImportSpeciesServiceRequestConfiguration } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesServiceRequestConfiguration';
import { equalTrue } from 'src/app/shared/input-components/validators/equalTrue.validator';
import {
  EsImportReleaseGetBanDisclaimersDto,
  EsImportReleaseGetPortsDto,
} from 'src/app/core/api-services/shared/EsGetTypeOfImportReleaseClassDto';
import { CitesImporterDetails } from 'src/app/core/models/cites/shared/CitesImporterDetails';
import { CitesExporterDetails } from 'src/app/core/models/cites/shared/CitesExporterDetails';
import {
  CitesClassCodes,
  CitesPurposeCodes,
  CitesSourceCodes,
  CitesTypeCodes,
} from 'src/app/core/models/cites/shared/CitesEnums';
import { RegisteredUserTypesEnum } from 'src/app/core/models/profile/RegisteredUser';
import {
  ApisFacadeService,
  NativesFacadeService,
  UtilsFacadeService,
} from 'src/app/core/services/state-services/state-management/dispatchers-facade.service';

@Injectable()
export class CitesImportSpeciesWorkflowService extends CitesWorkflowBaseService<CitesImportSpeciesStore> {
  private get exporterCountry(): AbstractControl {
    return this.store.form.controls.exporterCountry;
  }

  MaxItemCount: number = 4;
  FalconMaxItemCount: number = 20;

  getServiceUrlKeyWord(): string {
    return 'CitesImportSpecies';
  }

  private get isAuthority(): boolean {
    return (
      this.utils.utilService.getUserProfile().registeredUserType ==
      RegisteredUserTypesEnum.Authority
    );
  }

  constructor(
    protected natives: NativesFacadeService,
    protected utils: UtilsFacadeService,
    protected apis: ApisFacadeService,
    private apiService: CitesImportSpeciesService,
    protected mapper: CitesImportSpeciesMapper,
  ) {
    super(natives, utils, apis, apiService, mapper);

    this.initializeObservables();
  }
  ////////////////////////////////////load service data////////////////////////////
  private initializeObservables() {
    this.subscribeToServiceDetails();
    this.subscribeToFieldsOptionsChanges();
    this.subscribeToServiceConfiguration();
    this.subscribeToMetadata();
  }

  private subscribeToServiceDetails(): void {
    const store = this.store;

    const serviceDetails = new CitesImportSpeciesTransaction();
    serviceDetails.serviceTransaction = new CitesImportSpeciesTransactionData();

    store.serviceDetails$ = new BehaviorSubject(serviceDetails);
    store.serviceDetails$.subscribe((sd) => this.onServiceDetailsLoad(sd));
  }

  async onServiceDetailsLoad(
    serviceDetails: CitesImportSpeciesTransaction
  ): Promise<void> {
    if (this.isNotInitialized()) return;
    this.store.serviceDetails = serviceDetails;

    this.store.serviceDetails.consumedQuantities = [];
    this.store.serviceDetails.maxItemCount = this.MaxItemCount;
  }

  protected override onServiceConfigurationLoad(
    serviceConfig: CitesImportSpeciesServiceRequestConfiguration
  ): void {
    super.onServiceConfigurationLoad(serviceConfig);
    if (this.isNewRequest) {
      this.store.serviceDetails.serviceTransaction.importerDetails =
        this.mapper.mapImporterDetails(serviceConfig.userDetails);
      this.store.form.controls.importerAddress.setValue(
        serviceConfig.userDetails.address
      );
    }
    this.store.serviceDetails.countryDeclarationURL =
      serviceConfig.declarationUrl;

    if (this.isAuthority) {
      this.store.form.removeControl('isSelfExported');
    }
  }

  protected override initializeServiceDetailForm(): CitesImportSpeciesStore {
    const store = new CitesImportSpeciesStore();

    store.url = `/services/${this.getServiceUrlKeyWord()}/requestDetails`;

    store.form = new FormGroup({
      purpose: new FormControl(null, Validators.required),
      arrivalDate: new FormControl(
        null,
        optionalMoreThanOrEqualDate(new Date())
      ),
      entryPort: new FormControl(null, Validators.required),
      specialCases: new FormControl(
        null,
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)
      ),
      importerAddress: new FormControl(null, [
        Validators.required,
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
      ]),
      isSelfExported: new FormControl(null),
      exporterName: new FormControl(null, [
        Validators.required,
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
      ]),
      exporterCountry: new FormControl(null, Validators.required),
      exporterAddress: new FormControl(null, [
        Validators.required,
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
      ]),
    });

    store.formItem = new FormGroup({
      class: new FormControl(null, Validators.required),
      appendix: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      accompanyingCertificateNo: new FormControl(null),
      accompanyingCertificateDate: new FormControl(null),
      originCountry: new FormControl(null, Validators.required),
      exportingCertificateNo: new FormControl(null),
      exportingCertificateDate: new FormControl(null),
      lastReExportCountry: new FormControl(null),
      lastReExportCertificateDate: new FormControl(
        null,
        lessThanOrEqualDate(new Date())
      ),
      lastReExportCertificateNumber: new FormControl(null, [
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
      ]),
      specimenDescription: new FormControl(
        null,
        Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)
      ),
      source: new FormControl(null, Validators.required),
    });

    store.formItemStatus = { mode: FormMode.NEW };

    store.flowButtons = this.buildServiceConfigurationFlowButtons();

    return store;
  }

  protected bindMainFormTransaction(): void {
    let serviceTransaction = this.store.serviceDetails.serviceTransaction;

    let form = this.store.form;

    type ObjectKey = keyof typeof serviceTransaction;
    type ExporterObjectKey = keyof typeof serviceTransaction.exporterDetails;
    type ImporterObjectKey = keyof typeof serviceTransaction.importerDetails;
    Object.keys(form.controls).forEach((key) => {
      if (serviceTransaction[key as ObjectKey])
        form.controls[key].setValue(serviceTransaction[key as ObjectKey]);
      else if (serviceTransaction.exporterDetails[key as ExporterObjectKey])
        form.controls[key].setValue(
          serviceTransaction.exporterDetails[key as ExporterObjectKey]
        );
      else if (serviceTransaction.importerDetails[key as ImporterObjectKey])
        form.controls[key].setValue(
          serviceTransaction.importerDetails[key as ImporterObjectKey]
        );
    });

    this.store.form.controls.arrivalDate.setValue(
      this.mapper.convertToDisplayDate(
        this.store.serviceDetails.serviceTransaction.arrivalDate
      )
    );

    let isFalcon = true;
    this.store.serviceDetails.serviceTransaction.items.map((i) => {
      this.store.serviceDetails.consumedQuantities.push({
        itemId: i.type.id,
        quantity: i.quantity,
      });
      isFalcon = isFalcon && this.isFalcon(i.class.code);
    });
    if (isFalcon) {
      let cls = this.store.serviceDetails.serviceTransaction.items[0].class;
      this.store.serviceDetails.maxItemCount = this.FalconMaxItemCount;
      this.store.serviceDetails.disableClass = true;
      this.store.formItem.controls.class.disable();
      this.store.formItem.controls.class.setValue(cls);
      this.onClassChange(cls);
    } else this.store.serviceDetails.maxItemCount = this.MaxItemCount;

    this.onCountryChange(
      this.store.serviceDetails.serviceTransaction.exporterDetails
        .exporterCountry
    );
    this.updateRelatedTypes();
    this.loadPorts();
  }
  ///////////////////////////////////loadServiceRequest///////////////////////////////
  async loadServiceRequest(): Promise<void> {
    const requestId = this.store.id.toString();

    const response = await this.apiService
      .getRequest<GetCitesImportSpeciesResponse>(requestId)
      .toPromise();

    this.setApplicant(response.Data);

    this.globalObject.AttachmentsConfiguration.attachments =
      this.mapper.mapAttachmentsInstances(response.Data.AttachmentDetails);

    this.store.serviceDetails$.next(this.mapper.mapUltimate(response.Data));
  }
  ////////////////////////////////////create service request models///////////////
  protected createRequestData() {
    var transaction = this.getTransactionData();
    transaction.items = this.store.serviceDetails.serviceTransaction.items;

    const result = this.mapper.toDto(transaction);

    result.ProcedureInstanceID = this.store.id;

    return result;
  }
  ////////////////////////////////////custom methods//////////////////////
  public onSelfExported(isSelfExported: boolean) {
    var config = this.store
      .serviceConfig as CitesImportSpeciesServiceRequestConfiguration;

    if (isSelfExported) {
      var importerName =
        this.store.serviceDetails.serviceTransaction.importerDetails
          .importerName;
      this.store.serviceDetails.serviceTransaction.exporterDetails = {
        exporterName: importerName,
      };
      this.store.form.controls.exporterName.setValue(importerName);
      this.store.form.controls.exporterName.disable();
      config.filteredPurposes = config.mainLookups.operationPurposes.filter(
        (i) => i.code != CitesPurposeCodes.T
      );

      if (this.store.form.controls.purpose.value?.code == CitesPurposeCodes.T) {
        this.store.form.controls.purpose.setValue(null);
      }
    } else {
      this.store.form.controls.exporterName.setValue(null);
      this.store.form.controls.exporterName.enable();
      config.filteredPurposes = config.mainLookups.operationPurposes;
    }
  }

  onCountryChange(country: CountryLkp) {
    this.showHideItemForm();
    if (country) {
      if (country.showDeclaration) {
        this.store.form.addControl(
          'countryDeclaration',
          new FormControl(null, equalTrue())
        );
      } else {
        this.store.form.removeControl('countryDeclaration');
      }
    }
  }

  onPurposeChange(purpose: Lookup) {
    this.showHideItemForm();
    var cls = this.store.formItem.controls.class?.value;
    this.filterLookups(cls, purpose);
    if (
      (purpose?.code == CitesPurposeCodes.P ||
        purpose?.code == CitesPurposeCodes.T) &&
      this.store.formItem.controls.source?.value?.code == CitesSourceCodes.I
    )
      this.store.formItem.controls.source?.setValue(null);
  }

  showHideItemForm() {
    var country = this.store.form.controls.exporterCountry?.value;
    var purpose = this.store.form.controls.purpose?.value;
    this.store.serviceDetails.showItemsForm = country && purpose;
  }

  onClassChange(cls: Lookup, appendixId?: number) {
    var purpose = this.store.form.controls.purpose?.value;
    this.filterLookups(cls, purpose);
    if (cls) {
      if (appendixId == null) {
        appendixId = this.store.formItem.controls.appendix.value?.id;
        Object.keys(this.store.formItem.controls).forEach((key) => {
          if (key != 'class') this.store.formItem.controls[key].reset();
        });
      }

      this.loadINRTypes(cls.id, appendixId);

      if (this.isIdentifiable(cls.code)) {
        this.setIdentifiableItemFrom();
      } else {
        this.setNonIdentifiableItemForm(cls.code);
      }
    }
  }

  async onTypeChange(
    typeId: number,
    classCode?: string,
    itemQuantity?: number
  ) {
    if (classCode == null)
      classCode = this.store.formItem.controls.class.value?.code;
    if (this.isIdentifiable(classCode)) {
      this.store.formItem.removeControl('actualQuantity');
      this.store.formItem.removeControl('quantity');
    } else {
      await this.addQuantityControl(typeId, itemQuantity);
    }
  }

  async onIsRelatedChange(isRelated: boolean) {
    if (isRelated) {
      this.store.formItem.addControl(
        'relatedType',
        new FormControl(null, Validators.required)
      );
      this.store.formItem.removeControl('actualQuantity');
      this.store.formItem.removeControl('quantity');
    } else {
      this.store.formItem.removeControl('relatedType');
      await this.addQuantityControl(
        this.store.formItem.controls.type.value?.id
      );
    }
  }

  onAppendixChange(appendix: Lookup) {
    if (appendix) {
      this.loadINRTypes(
        this.store.formItem.controls.class.value?.id,
        appendix.id
      );
      this.store.formItem.controls.type.reset();
      if (this.isOriginCertificateRequired(appendix.code)) {
        this.store.formItem.controls.accompanyingCertificateNo.setValidators([
          Validators.required,
          Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
        ]);
        this.store.formItem.controls.accompanyingCertificateDate.setValidators([
          Validators.required,
          lessThanOrEqualDate(new Date()),
        ]);
        this.store.formItem.controls.exportingCertificateNo.setValidators([
          Validators.required,
          Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
        ]);
        this.store.formItem.controls.exportingCertificateDate.setValidators([
          Validators.required,
          lessThanOrEqualDate(new Date()),
        ]);
      } else {
        this.store.formItem.controls.accompanyingCertificateNo.setValidators(
          Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)
        );
        this.store.formItem.controls.accompanyingCertificateDate.setValidators(
          lessThanOrEqualDate(new Date())
        );
        this.store.formItem.controls.exportingCertificateNo.setValidators(
          Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)
        );
        this.store.formItem.controls.exportingCertificateDate.setValidators(
          lessThanOrEqualDate(new Date())
        );
      }
    }
  }

  onSourceChange(sourceId: number) {
    if (sourceId) {
      this.store.formItem.removeControl('sourceReason');
    } else {
      this.store.formItem.addControl(
        'sourceReason',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars),
        ])
      );
    }
  }

  async onTypeDescriptionChange(typeDescId: number) {
    await this.loadTypeDescUnits(
      typeDescId,
      this.store.formItem.controls.type.value
    );
    this.store.formItem.controls.unit?.reset();
    if (this.store.serviceConfig.mainLookups.packageUnits.length == 1) {
      this.store.formItem.controls.unit?.setValue(
        this.store.serviceConfig.mainLookups.packageUnits[0]
      );
    }
  }

  onOriginCountryChange(countryId: number) {
    const sourceCountry = this.store.form.controls.exporterCountry.value?.id;
    const classId = this.store.formItem.controls.class.value?.id;
    const typeId = this.store.formItem.controls.type.value?.id;

    if (sourceCountry && classId && countryId && typeId) {
      this.loadBanDisclaimer(classId, typeId, countryId, sourceCountry);
    }
  }

  async onRingNumberChange(ringNo: string) {
    if (ringNo) {
      let isValid = true;
      if (
        this.store.itemInstance?.ringNumber == null ||
        ringNo != this.store.itemInstance.ringNumber
      ) {
        isValid =
          this.store.serviceDetails.serviceTransaction.items.filter(
            (i) => i.ringNumber == ringNo
          ).length == 0;
      }

      if (isValid) {
        let requestId = isNaN(this.store.id) ? 0 : this.store.id;
        const response = await this.apiService
          .ValidateRingNumber(ringNo, requestId)
          .toPromise();
        if (response) {
          if (response.Data)
            this.store.formItem.controls.ringNumber.setErrors(null);
          else
            this.store.formItem.controls.ringNumber.setErrors({
              customError: true,
            });
        }
      } else {
        setTimeout(() => {
          this.store.formItem.controls.ringNumber.setErrors({
            customError: true,
          });
        });
      }
    }
  }

  async onChipNumberChange(chipNo: string) {
    if (chipNo) {
      let isValid = true;

      if (
        this.store.itemInstance?.chipNumber == null ||
        chipNo != this.store.itemInstance.chipNumber
      ) {
        isValid =
          this.store.serviceDetails.serviceTransaction.items.filter(
            (i) => i.chipNumber == chipNo
          ).length == 0;
      }

      if (isValid) {
        let requestId = isNaN(this.store.id) ? 0 : this.store.id;
        const response = await this.apiService
          .ValidateChipNumber(chipNo, requestId)
          .toPromise();
        if (response) {
          if (response.Data)
            this.store.formItem.controls.chipNumber.setErrors(null);
          else
            this.store.formItem.controls.chipNumber.setErrors({
              customError: true,
            });
        }
      } else {
        setTimeout(() => {
          this.store.formItem.controls.chipNumber.setErrors({
            customError: true,
          });
        });
      }
    }
  }

  private async loadINRTypes(classId: number, appendixId: number) {
    if (classId && appendixId) {
      const response = await this.apiService
        .GetINRTypes(classId, appendixId)
        .toPromise();
      if (response && response.Data) {
        this.store.serviceConfig.mainLookups.INRTypes =
          this.mapper.sharedMapper.mapINRTypeLookup(response.Data);
      }
    }
  }

  private async loadTypeDescUnits(typeDescId: number, type: INRTypeLkp) {
    if (typeDescId) {
      if (this.store.formItem.contains('actualQuantity')) {
        //has max quantity
        this.store.serviceConfig.mainLookups.packageUnits = [
          {
            id: type.unitId,
            nameAr: type.unitNameAr,
            nameEn: type.unitNameEn,
          },
        ];
      } else {
        const response = await this.apiService
          .GetTypeDescUnits(typeDescId)
          .toPromise();
        if (response && response.Data) {
          this.store.serviceConfig.mainLookups.packageUnits = this.mapLookup(
            response.Data
          );
        }
      }
    }
  }

  private setIdentifiableItemFrom() {
    this.store.formItem.addControl(
      'ringNumber',
      new FormControl(null, Validators.required)
    );
    this.store.formItem.addControl('chipNumber', new FormControl(null));
    this.store.formItem.addControl(
      'gender',
      new FormControl(null, Validators.required)
    );
    this.store.formItem.addControl(
      'birthdate',
      new FormControl(null, lessThanOrEqualDate(new Date()))
    );
    this.store.formItem.removeControl('typeDescription');
    this.store.formItem.removeControl('unit');
    this.store.formItem.removeControl('isRelated');
    this.store.formItem.removeControl('relatedType');
  }

  private setNonIdentifiableItemForm(classCode: string) {
    this.store.formItem.addControl(
      'typeDescription',
      new FormControl(null, Validators.required)
    );
    this.store.formItem.addControl(
      'unit',
      new FormControl(null, Validators.required)
    );
    this.store.formItem.removeControl('ringNumber');
    this.store.formItem.removeControl('chipNumber');
    this.store.formItem.removeControl('gender');
    this.store.formItem.removeControl('birthdate');
    this.store.formItem.removeControl('relatedType');
    if (
      this.isAnimalProduct(classCode) &&
      this.store.serviceDetails.serviceTransaction.items.length > 0
    ) {
      this.store.formItem.addControl('isRelated', new FormControl(null));
    } else {
      this.store.formItem.removeControl('isRelated');
    }
  }

  private updateRelatedTypes() {
    this.store.serviceConfig.mainLookups.relatedTypes = [];
    this.store.serviceDetails.serviceTransaction.items.map((item) => {
      if (!item.isRelated) {
        this.store.serviceConfig.mainLookups.relatedTypes.push({
          id: item.type.id,
          nameAr: item.type.scientificName,
          nameEn: item.type.scientificName,
        });
      }
    });
  }

  private async addQuantityControl(typeId: number, itemQuantity: number = 0) {
    if (typeId) {
      const config = this.store
        .serviceConfig as CitesImportSpeciesServiceRequestConfiguration;
      let allowedQuantity = 99999;
      this.store.formItem.removeControl('actualQuantity');
      this.store.formItem.removeControl('quantity');
      if (config.validateQuantity) {
        const serverAllowedQuantity: number =
          await this.getServerAllowedQuantity(typeId);
        if (serverAllowedQuantity != null) {
          allowedQuantity = this.getAllowedQuantity(
            typeId,
            serverAllowedQuantity,
            itemQuantity
          );
          this.store.formItem.addControl(
            'actualQuantity',
            new FormControl(allowedQuantity)
          );
        }
      }
      this.store.formItem.addControl(
        'quantity',
        new FormControl(null, [
          Validators.required,
          Validators.min(0.00000000001),
          Validators.max(allowedQuantity),
        ])
      );
    }
  }

  private async getServerAllowedQuantity(typeId: number) {
    let requestId = isNaN(this.store.id) ? 0 : this.store.id;
    const response = await this.apiService
      .GetAllowedQuantity(typeId, requestId)
      .toPromise();
    if (response) {
      return response.Data;
    } else {
      return -1;
    }
  }

  private getAllowedQuantity(
    typeId: number,
    serverOriginalQuantity: number,
    itemQuantity: number
  ): number {
    let totalConsumnedQuantity =
      this.store.serviceDetails.serviceTransaction.items
        ?.filter((i) => i.type.id == typeId)
        .reduce((sum, current) => sum + current.quantity, 0);
    let serverConsumnedQuantity = this.store.serviceDetails.consumedQuantities
      .filter((i) => i.itemId == typeId)
      .reduce((sum, current) => sum + current.quantity, 0);
    //console.log(`(${serverOriginalQuantity}+${serverConsumnedQuantity}) - (${totalConsumnedQuantity} - ${itemQuantity})`);
    return (
      serverOriginalQuantity +
      serverConsumnedQuantity -
      (totalConsumnedQuantity - itemQuantity)
    );
  }

  private async loadPorts() {
    const exportCountry = this.exporterCountry.value as CountryLkp;
    const items = this.store.serviceDetails.serviceTransaction.items;
    if (exportCountry?.id && items.length > 0) {
      let request: EsImportReleaseGetPortsDto = {
        CountryId: exportCountry.id,
        CountriesTypes: items.map((i) => {
          return {
            ClassId: i.class.id,
            CountryId: i.originCountry.id,
            TypeId: i.type.id,
          };
        }),
        ShipmentMethodId: null,
      };
      const portsResponse = await this.apis.commonApiService
        .getAllowedPorts(request)
        .toPromise();

      this.store.serviceConfig.mainLookups.entryPorts = this.mapLookup(
        portsResponse.Data
      );
    }
  }

  private async loadBanDisclaimer(
    classId: number,
    typeId: number,
    originCountryId: number,
    sourceCountryId: number
  ) {
    const request: EsImportReleaseGetBanDisclaimersDto = {
      procedureID: this.store.serviceConfig.procedureId,
      classID: classId,
      typeID: typeId,
      countries: [sourceCountryId, originCountryId],
    };

    const response = await this.apis.commonApiService
      .getImportReleaseBanDisclaimers(request)
      .toPromise();
    if (response && response.Data) {
      this.store.serviceDetails.disclaimers = this.mapper.mapAlertFromLookup(
        response.Data
      );
    }
  }

  private async initializeItemForm(item: CitesImportSpeciesItem) {
    this.onClassChange(item.class, item.appendix.id);
    await this.onTypeChange(item.type.id, item.class.code, item.quantity);
    if (item.isRelated) await this.onIsRelatedChange(item.isRelated);
    this.onAppendixChange(item.appendix);
    this.onSourceChange(item.source.id);
    await this.loadTypeDescUnits(item.typeDescription?.id, item.type);
    this.onOriginCountryChange(item.originCountry.id);
  }

  public override async push(): Promise<void> {
    const isFirstItem =
      this.store.serviceDetails.serviceTransaction.items.length == 0;
    const item = this.fromFormGroup(this.store.formItem);
    await super.push();
    this.updateRelatedTypes();
    if (isFirstItem) {
      if (this.isFalcon(item.class.code)) {
        this.store.serviceDetails.disableClass = true;
        this.store.serviceDetails.maxItemCount = this.FalconMaxItemCount;
      }
    }
    if (this.store.serviceDetails.disableClass) {
      this.store.formItem.controls.class.disable();
      this.store.formItem.controls.class.setValue(item.class);
    }
    this.loadPorts();
  }

  override async startItemEditing($event: any) {
    let item = $event.data as CitesImportSpeciesItem;
    await this.initializeItemForm(item);
    super.startItemEditing($event, ['actualQuantity']);
    if (this.isIdentifiable(item.class?.code)) {
      await Promise.all([
        this.onRingNumberChange(item.ringNumber),
        this.onChipNumberChange(item.chipNumber),
      ]);
    }
  }

  override deleteItem($event: any) {
    super.deleteItem($event);
    this.updateRelatedTypes();
    if (this.store.serviceDetails.serviceTransaction.items.length == 0) {
      this.store.serviceDetails.disableClass = false;
      this.store.serviceDetails.maxItemCount = this.MaxItemCount;
      this.store.formItem.controls.class.enable();
    }
  }

  override resetItemForm(): void {
    this.store.formItemStatus.mode = FormMode.NEW;
    const formGroup = this.store.formItem;
    Object.keys(formGroup.controls).forEach((key) => {
      let ctr = (formGroup.controls as any)[key] as AbstractControl;
      if (key == 'class' && ctr.disabled) return;
      else ctr.reset();
    });
    this.store.itemInstance = null;
  }

  async copyItem($event: any) {
    let item = Object.assign({}, $event.data) as CitesImportSpeciesItem;
    item.quantity = 0;
    await this.initializeItemForm(item);
    this.store.formItemStatus.mode = FormMode.NEW;
    this.fillItemForm($event, [
      'quantity',
      'actualQuantity',
      'ringNumber',
      'chipNumber',
    ]);
    this.scrollToConsignmentItems();
  }

  async DownloadVerificationDocument(certificateNo: string) {
    let content = await this.apiService
      .DownloadVerifiedDocument(certificateNo, this.store.id)
      .toPromise();
    this.download(
      JSON.stringify(content),
      `${certificateNo}.json`,
      'text/plain'
    );
  }

  initiateReleaseRequest(): void {
    this.natives.router.navigateByUrl(this.store.serviceDetails.releaseURL);
  }

  private getTransactionData(): CitesImportSpeciesTransactionData {
    const mainForm = this.store.form;

    this.store.serviceDetails.serviceTransaction.exporterDetails =
      this.fromFormGroup(mainForm) as CitesExporterDetails;

    this.store.serviceDetails.serviceTransaction.importerDetails = {
      ...this.store.serviceDetails.serviceTransaction.importerDetails,
      ...(this.fromFormGroup(mainForm) as CitesImporterDetails),
    };

    let transaction = this.fromFormGroup(
      mainForm
    ) as CitesImportSpeciesTransactionData;

    transaction.exporterDetails =
      this.store.serviceDetails.serviceTransaction.exporterDetails;

    transaction.importerDetails =
      this.store.serviceDetails.serviceTransaction.importerDetails;

    return transaction;
  }

  protected override createDraftRequestData(): any {
    return this.getTransactionData();
  }

  protected override async validateItems(): Promise<boolean> {
    const valid = await super.validateItems();

    if (valid) {
      const items = this.store.serviceDetails.serviceTransaction.items;

      for (let item of items) {
        await this.startItemEditing({ data: item });

        if (this.store.formItem.valid) {
          this.resetItemForm();
          continue;
        } else {
          this.store.formItem.markAllAsTouched();
          this.scrollToFirstError();
          this.showItemsValidationError();
          return false;
        }
      }
    }
    return valid;
  }

  private filterLookups(cls: Lookup, purpose: Lookup) {
    var config = this.store
      .serviceConfig as CitesImportSpeciesServiceRequestConfiguration;
    config.filteredSources = this.store.serviceConfig.mainLookups.sources;
    config.filteredTypes = this.store.serviceConfig.mainLookups.types;
    if (cls?.code.indexOf(CitesClassCodes.Animal) > -1) {
      config.filteredSources =
        this.store.serviceConfig.mainLookups.sources.filter(
          (i) =>
            i.productType == CitesTypeCodes.All ||
            i.productType == CitesTypeCodes.Vet
        );
      config.filteredTypes = this.store.serviceConfig.mainLookups.types.filter(
        (i) =>
          i.productType == CitesTypeCodes.All ||
          i.productType == CitesTypeCodes.Vet
      );
    } else if (cls?.code.indexOf(CitesClassCodes.Plant) > -1) {
      config.filteredSources =
        this.store.serviceConfig.mainLookups.sources.filter(
          (i) =>
            i.productType == CitesTypeCodes.All ||
            i.productType == CitesTypeCodes.Agri
        );
      config.filteredTypes = this.store.serviceConfig.mainLookups.types.filter(
        (i) =>
          i.productType == CitesTypeCodes.All ||
          i.productType == CitesTypeCodes.Agri
      );
    }
    if (
      purpose?.code == CitesPurposeCodes.P ||
      purpose?.code == CitesPurposeCodes.T
    ) {
      config.filteredSources = config.filteredSources.filter(
        (i) => i.code != CitesSourceCodes.I
      );
    }
  }
}
