import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app.module';
import { PageBaseComponent } from 'src/app/shared/base/PageBaseComponent';
import {
  ColOptions,
  ColSpace,
  ScreenMediaSize,
} from '../../services/es-shared/ds-col/ds-col.component';
import {
  SettingsService,
  SettingsSizeEmitterData,
} from '../settings/settings.service';

export abstract class EsRequestPageBaseComponent extends PageBaseComponent {
  protected settingsService: SettingsService;

  constructor(translateService: TranslateService) {
    super(translateService);

    this.settingsService = AppInjector.get(SettingsService);
  }

  public mainColOptions: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.TwoThirds,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'wrapper',
  };

  public progressBarColumnOptions: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Two,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  public breadcrumbsColumnOptions: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Half,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  public changeSize(sizeSettings: SettingsSizeEmitterData): void {
    if (sizeSettings.size == 'Full') {
      this.setSizeFull();
    }

    if (sizeSettings.size == 'Normal') {
      this.setSizeNormal();
    }
  }

  protected setSizeNormal() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.TwoThirds;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.Half;
    this.progressBarColumnOptions.maximumSize.colSpace = ColSpace.Two;
  }

  protected setSizeFull() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.Full;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.TwoThirds;
    this.progressBarColumnOptions.maximumSize.colSpace = ColSpace.Third;
  }

  protected subscribeToSizeSettings() {
    this.settingsService.setSupportResizing(true);
    this.settingsService.size$.subscribe((s) => {
      this.changeSize(s);
    });
  }
}
