import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';

@Injectable()
export class ServiceRequestLoadedStateManager {
  private subject = new Subject<EsBaseServiceRequest>();
  public observable$ = this.subject.asObservable();

  public next(result: EsBaseServiceRequest) {
    this.subject.next(result);
  }
}

@Injectable()
export class DraftServiceRequestLoadedStateManager {
  private subject = new Subject<EsBaseServiceRequest>();
  public observable$ = this.subject.asObservable();

  public next(result: EsBaseServiceRequest) {
    this.subject.next(result);
  }
}
