import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { InputComponentsModule } from 'src/app/shared/input-components/input-components.module';
import { SharedModule } from 'src/app/shared/shared-module/shared-module.module';
import { DsColComponent } from './ds-col/ds-col.component';
import { EsApplicantInformationComponent } from './es-applicant-information/es-applicant-information.component';
import { EsAttachmentsDetailsComponent } from './es-attachments-details/es-attachments-details.component';
import { ImportAttachmentsComponent } from './es-attachments-page/es-attachments-page.component';
import { FormSectionDirective } from './es-form-section-directive/FormSectionDirective';
import { EsLicenseDetailsComponent } from './es-license-details/es-license-details.component';
import { EsMainButtonsComponent } from './es-main-buttons/es-main-buttons.component';
import { EsOutputsComponent } from './es-outputs/es-outputs.component';
import { EsReceiptDetailsComponent } from './es-receipt-details/es-receipt-details.component';
import { EsRequestCardExtraComponent } from './es-request-card-extra/es-request-card-extra.component';
import { EsServiceDisclaimersComponent } from './es-service-disclaimers/es-service-disclaimers.component';
import { EsRequestDetailsComponent } from './es-service-request-details/es-service-request-details.component';
import { EsTermsAndConditionsComponent } from './es-terms-and-conditions/es-terms-and-conditions.component';
import { EsUserDetailsComponent } from './es-user-details/es-user-details.component';
import { EsViewComponent } from './es-view-page/es-view-page.component';
import { ExportFeeListComponent } from './es-fee-list/es-fee-list.component';
import { EsViewPageNewComponent } from './es-view-page-new/es-view-page-new.component';
import { EsReviewPageComponent } from './es-review-page/es-review-page.component';
import { EsIdentityOtpMapper } from './es-identity-otp-form/EsIdentityOtpMapper';
import { EsSignaturePadComponent } from './es-signature-pad/es-signature-pad.component';
import { EsRequestPageComponent } from './es-request-page/es-request-page.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EsServiceTitleComponent } from './es-service-title/es-service-title.component';
import { EsRequestTemplateFormComponent } from './es-request-template-form/es-request-template-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EsMainItemFormButtonsComponent } from './es-main-item-form-buttons/es-main-item-form-buttons.component';
import {
  UtilsFacadeService,
  NativesFacadeService,
  ApisFacadeService,
} from 'src/app/core/services/state-services/state-management/dispatchers-facade.service';

@NgModule({
  declarations: [
    EsRequestDetailsComponent,
    EsReceiptDetailsComponent,
    EsAttachmentsDetailsComponent,
    EsMainButtonsComponent,
    EsServiceDisclaimersComponent,
    EsTermsAndConditionsComponent,
    EsLicenseDetailsComponent,
    EsUserDetailsComponent,
    EsOutputsComponent,
    EsViewComponent,
    EsViewPageNewComponent,
    EsReviewPageComponent,
    FormSectionDirective,
    EsApplicantInformationComponent,
    ImportAttachmentsComponent,
    DsColComponent,
    ExportFeeListComponent,
    EsSignaturePadComponent,
    EsRequestPageComponent,
    EsRequestCardExtraComponent,
    EsServiceTitleComponent,
    EsRequestTemplateFormComponent,
    EsMainItemFormButtonsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    SharedModule,
    InputComponentsModule,
    NgxFileDropModule,
    NgxMaskModule.forRoot(),
    TabsModule.forRoot(),
    NgxPaginationModule,
  ],
  exports: [
    EsRequestDetailsComponent,
    EsReceiptDetailsComponent,
    EsAttachmentsDetailsComponent,
    EsMainButtonsComponent,
    EsServiceDisclaimersComponent,
    EsTermsAndConditionsComponent,
    EsLicenseDetailsComponent,
    EsUserDetailsComponent,
    EsOutputsComponent,
    FormSectionDirective,
    NgxMaskModule,
    ImportAttachmentsComponent,
    DsColComponent,
    ExportFeeListComponent,
    EsSignaturePadComponent,
    EsRequestPageComponent,
    EsServiceTitleComponent,
    TabsModule,
    EsMainItemFormButtonsComponent,
  ],
  providers: [
    NativesFacadeService,
    UtilsFacadeService,
    ApisFacadeService,
    DatePipe,
    EsIdentityOtpMapper,
  ],
})
export class EsSharedModule {}
