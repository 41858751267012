import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { FormMode } from "src/app/shared/base/FormMode";
import { WorkflowSteps } from "src/app/shared/base/WorkflowSteps";
import { CitesWorkflowBaseService } from "../shared/cites-workflow-base.service";
import { CitesReleaseSpeciesStore } from "./CitesReleaseSpeciesStore";
import { CitesReleaseSpeciesService } from "src/app/core/api-services/cites/cites-release-species/cites-release-species.service";
import { CitesReleaseSpeciesMapper } from "./CitesReleaseSpeciesMapper";
import { CitesReleaseSpeciesTransaction, CitesReleaseSpeciesTransactionData } from "src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction";
import { CitesReleaseSpeciesServiceRequestConfiguration } from "src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestConfiguration";
import { GetCitesReleaseSpeciesResponse } from "src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesResponse";
import { InputPatterns } from "src/app/shared/input-components/validators/InputPatterns";
import { CitesItemStatusEnum } from "src/app/core/enums/CitesItemStatusEnum";
import { ApisFacadeService, NativesFacadeService, UtilsFacadeService } from "src/app/core/services/state-services/state-management/dispatchers-facade.service";

@Injectable()
export class CitesReleaseSpeciesWorkflowService
    extends CitesWorkflowBaseService<CitesReleaseSpeciesStore>{

    get isReleaseInitiated():boolean{
        return (this.store?.serviceConfig as CitesReleaseSpeciesServiceRequestConfiguration)?.isReleaseInitiated;
    }

    get isValidForRelease():boolean{
        return (this.store?.serviceConfig as CitesReleaseSpeciesServiceRequestConfiguration)?.isValidForRelease;
    }
    

    getServiceUrlKeyWord(): string {
        return "CitesReleaseSpecies"
    }

    constructor(
        protected natives: NativesFacadeService,
        protected utils: UtilsFacadeService,
        protected apis: ApisFacadeService,
        private apiService: CitesReleaseSpeciesService,
        protected mapper: CitesReleaseSpeciesMapper,
        ) {
        super(
            natives,
            utils,
            apis,
            apiService,
            mapper,
        );

        this.initializeObservables();
    }
////////////////////////////////////load service data////////////////////////////
    private initializeObservables() {
        this.subscribeToServiceDetails();
        this.subscribeToFieldsOptionsChanges();
        this.subscribeToServiceConfiguration();
        this.subscribeToMetadata();
    }

    private subscribeToServiceDetails(): void {
        const store = this.store;

        const serviceDetails = new CitesReleaseSpeciesTransaction();
        serviceDetails.serviceTransaction = new CitesReleaseSpeciesTransactionData();

        store.serviceDetails$ = new BehaviorSubject(serviceDetails);
        store.serviceDetails$.subscribe((sd) => this.onServiceDetailsLoad(sd));
    }

    protected override onServiceConfigurationLoad(serviceConfig: CitesReleaseSpeciesServiceRequestConfiguration): void {
        super.onServiceConfigurationLoad(serviceConfig);
        if (serviceConfig.importRequest) {
            if (this.isValidForRelease && !this.isReleaseInitiated) {
                this.store.id = null;
                serviceConfig.importRequest.id = null;

                this.store.serviceDetails.serviceTransaction = serviceConfig.importRequest;

                this.store.serviceDetails.serviceTransaction.releaseItems =
                    JSON.parse(JSON.stringify(serviceConfig.importRequest.items));
                
                this.store.serviceDetails.serviceTransaction.releaseItems.map(i=>{
                    i.isIdentifiable = this.isIdentifiable(i.class.code);
                    i.isImported = i.status == null || i.status == CitesItemStatusEnum.Imported;
                    i.isReleased = i.status == CitesItemStatusEnum.Released;
                    i.isSelected = i.isImported;
                    i.show = i.isSelected || i.isImported;
                    i.status = i.isSelected ? CitesItemStatusEnum.Selected: i.status;
                });

                this.emitFieldsChange();
                this.bindMainFormTransaction();
            }
        }
    }

    protected override async loadMetadata(): Promise<void> {
        const metadataResponse = await this.apis.metadataService
          .getServiceDetailByDigitalNumber(this.store.importProcedureId.toString())
          .toPromise();

        const requestTypeCode =
          this.natives.route.snapshot.paramMap.get('requestTypeCode');

        const serviceMetaData = this.mapper.mapMetadata(
          metadataResponse.responseData,
          requestTypeCode
        );

        this.store.serviceMetaData$.next(serviceMetaData);
    }

    protected override isCustomerAllowedToEditRequest() {
        if (this.hasReleaseRequest())
            return super.isCustomerAllowedToEditRequest();
        else
            return true;
    }

    async onServiceDetailsLoad(
        serviceDetails: CitesReleaseSpeciesTransaction
    ): Promise<void> {
        if (this.isNotInitialized()) return;
        this.store.serviceDetails =
            serviceDetails;
    }

    protected override initializeServiceDetailForm(): CitesReleaseSpeciesStore {
        const store = new CitesReleaseSpeciesStore();

        store.url = `/services/${this.getServiceUrlKeyWord()}/requestDetails`;

        store.form = new FormGroup({
            shippingBillNumber: new FormControl(null,
                Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)),
            shippingMethod: new FormControl(null,Validators.required),
            shippingPolicy: new FormControl(null, [
                Validators.required,
                Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)]),
            tanker: new FormControl(null, [
                Validators.required,
                Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)]),
            exportingPortForRelease: new FormControl(null, [
                Validators.required,
                Validators.pattern(InputPatterns.OnlyEnglishWithSpecialChars)]),
            checkinDate: new FormControl(null,Validators.required),
        });

        store.formItem = new FormGroup({});

        store.formItemStatus = { mode: FormMode.NEW };

        store.flowButtons = this.buildServiceConfigurationFlowButtons();

        return store;
    }

    protected bindMainFormTransaction(): void {
        let serviceTransaction =
            this.store.serviceDetails
            .serviceTransaction;

        let form = this.store.form;

        type ObjectKey = keyof typeof serviceTransaction;
        type ExporterObjectKey = keyof typeof serviceTransaction.exporterDetails;
        type ImporterObjectKey = keyof typeof serviceTransaction.importerDetails;
        Object.keys(form.controls).forEach((key) => {
            if(serviceTransaction[key as ObjectKey])
                form.controls[key].setValue(serviceTransaction[key as ObjectKey]);
            else if(serviceTransaction.exporterDetails[key as ExporterObjectKey])
                form.controls[key].setValue(serviceTransaction.exporterDetails[key as ExporterObjectKey]);
            else if(serviceTransaction.importerDetails[key as ImporterObjectKey])
                form.controls[key].setValue(serviceTransaction.importerDetails[key as ImporterObjectKey]);
        });

        this.store.form.controls.checkinDate.setValue(
            this.mapper.convertToDisplayDate(this.store.serviceDetails
                .serviceTransaction.checkinDate)
        );
    }
///////////////////////////////////loadServiceRequest///////////////////////////////
    hasReleaseRequest(): boolean {
        const serviceConfig = this.store.serviceConfig as (CitesReleaseSpeciesServiceRequestConfiguration)
        return (serviceConfig.isReleaseInitiated) || this.formMode == FormMode.VIEW;
    }

    async loadServiceRequest(): Promise<void> {
        if (this.hasReleaseRequest()) {
            const requestId =
                this.store.id.toString();

            const response = await this.apiService
                .getRequest<GetCitesReleaseSpeciesResponse>(requestId)
                .toPromise();

            this.setApplicant(response.Data);

            this.globalObject.AttachmentsConfiguration.attachments =
                this.mapper.mapAttachmentsInstances(response.Data.AttachmentDetails);

            this.store.serviceDetails$.next(
                this.mapper.mapUltimate(response.Data));
            
            this.store.serviceDetails.serviceTransaction.releaseItems.map(i=>{
                i.isIdentifiable = this.isIdentifiable(i.class.code);
                i.isImported = i.status == null || i.status == CitesItemStatusEnum.Imported;
                i.isReleased = i.status == CitesItemStatusEnum.Released;
                i.isSelected = i.status == CitesItemStatusEnum.Selected;
                i.show = this.formMode == FormMode.VIEW ? 
                    i.isSelected || i.isReleased : 
                    i.isSelected || i.isImported;
            });

            this.store.serviceDetails.serviceTransaction.items.map(i=>{
                i.show = true;
            });
        }
    }
////////////////////////////////////create service request models///////////////
    protected createRequestData() {
        const mainForm =
            this.store.form;

        let transaction = {
            ...this.store.serviceDetails.serviceTransaction,
            ...this.fromFormGroup(mainForm) as CitesReleaseSpeciesTransactionData,
        }

        transaction.exporterDetails =
            this.store.serviceDetails.serviceTransaction.exporterDetails;

        transaction.importerDetails =
            this.store.serviceDetails.serviceTransaction.importerDetails;

        transaction.items = this.store
                                    .serviceDetails.serviceTransaction.releaseItems;

        const result = this.mapper.toDto(transaction);

        return result;
    }
////////////////////////////////////custom methods//////////////////////
    public override startItemEditing($event: any): void {
        super.startItemEditing($event);

        const releaseServiceConfig = (this.store.serviceConfig as CitesReleaseSpeciesServiceRequestConfiguration);

        if(this.isIdentifiable(this.store.itemInstance.class.code)){
            this.store.formItem.removeControl("quantity");
        }
        else{
            this.store.itemInstance.isIdentifiable = false;
            const originalConsignment = releaseServiceConfig.importRequest.items
                .filter(i => i.id == this.store.itemInstance.id)[0];
            const maxQuantity = originalConsignment?.quantity?? this.store.itemInstance.quantity;
            this.store.formItem.addControl("quantity",new FormControl(
                this.store.itemInstance.quantity,
                [Validators.required,
                Validators.max(maxQuantity)]));
        }
    }


    public override deleteItem($event: any) {
        const index = this.store.serviceDetails.serviceTransaction.releaseItems.indexOf(
          $event.data
        );
    
        this.store.serviceDetails.serviceTransaction.releaseItems.splice(index, 1);
    }

    public override async push(): Promise<void> {
        const item = this.fromFormGroup(this.store.formItem);
        if (this.itemFormCustomValidation()) {
          if (this.store.formItemStatus.mode === FormMode.EDITING) {
            this.store.itemInstance.quantity = item.quantity;
          }
          this.resetItemForm();
          this.emitFieldsChange();
        }
    }

    public override setCurrentStep(step: WorkflowSteps): void {
        if(step == WorkflowSteps.ServiceDetailsConfiguration){
            this.store.serviceDetails.serviceTransaction.releaseItems.map(i=>{
                i.show = i.isSelected || i.isImported;
            });
        }
        else if(step == WorkflowSteps.ReviewConfiguration){
            this.store.serviceDetails.serviceTransaction.releaseItems.map(i=>{
                i.show = i.isSelected;
            });
        }
        super.setCurrentStep(step);
    }

    async DownloadVerificationDocument(certificateNo: string){
        let content = await this.apiService.DownloadVerifiedDocument(certificateNo,this.store.id).toPromise();
        this.download(JSON.stringify(content), `${certificateNo}.json`, 'text/plain');
    }

}
