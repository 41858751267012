export enum CitesClassCodes {
  Animal = 'Animal',
  Plant = 'Plant',
}

export enum CitesTypeCodes {
  All = 'All',
  Vet = 'Vet',
  Agri = 'Agri',
}

export enum CitesPurposeCodes {
  T = 'T',
  P = 'P',
}

export enum CitesSourceCodes {
  I = 'I',
}
