import { Injectable } from '@angular/core';
import {
  FormTemplateEnum,
  SettingsService,
  SettingsSizeEnum,
} from './settings.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SettingsLocalStorageService {
  private readonly key = 'app-settings';

  constructor(private settingsService: SettingsService) {}

  public initialize() {
    this.default();
    this.subscribeToSizeChange();
    this.subscribeToFormsTemplateChange();
    this.subscribeToFocusModeChange();
  }

  private subscribeToSizeChange(): void {
    this.settingsService.size$.subscribe((s) => {
      const appSettings: AppSettings = this.getAppSettings();

      appSettings.size = s.size;

      this.saveSettings(appSettings);
    });
  }

  private subscribeToFormsTemplateChange(): void {
    this.settingsService.formsTemplate$.subscribe((s) => {
      const appSettings: AppSettings = this.getAppSettings();

      appSettings.formsTemplate = s.template;

      this.saveSettings(appSettings);
    });
  }

  private subscribeToFocusModeChange(): void {
    this.settingsService.focusMode$.subscribe((s) => {
      const appSettings: AppSettings = this.getAppSettings();

      if (appSettings.focusMode) {
        appSettings.focusMode.isFocusMode = s.isFocusMode;
      } else {
        appSettings.focusMode = {
          isFocusMode: s.isFocusMode,
        };
      }

      this.saveSettings(appSettings);
    });
  }

  private getAppSettings(): AppSettings {
    const appSettingsString = localStorage.getItem(this.key);

    if (appSettingsString) {
      const appSettings: AppSettings = JSON.parse(appSettingsString);

      return appSettings;
    }

    return this.createDefaultSettings();
  }

  private default(): void {
    const appSettingsString = localStorage.getItem(this.key);

    if (appSettingsString) {
      const appSettings: AppSettings = JSON.parse(appSettingsString);

      if ((appSettings.version || 0) < environment.appSettingsVersion) {
        const appSettings = this.createDefaultSettings();
        this.saveSettings(appSettings);
        this.applySettings(appSettings);
      } else {
        this.applySettings(appSettings);
      }
    } else {
      const appSettings = this.createDefaultSettings();
      this.saveSettings(appSettings);
      this.applySettings(appSettings);
    }
  }

  private saveSettings(appSettings: AppSettings): void {
    localStorage.setItem(this.key, JSON.stringify(appSettings));
  }

  private applySettings(appSettings: AppSettings): void {
    if (appSettings?.size) {
      this.applySizeSettings(appSettings);
    }
    if (appSettings?.formsTemplate) {
      this.applyTemplateSettings(appSettings);
    }
    if (appSettings?.focusMode) {
      appSettings.focusMode.isFocusMode
        ? this.settingsService.enterToFocusMode()
        : this.settingsService.exitFocusMode();
    }
  }

  private applySizeSettings(appSettings: AppSettings): void {
    switch (appSettings.size) {
      case SettingsSizeEnum.Full:
        this.settingsService.changeSizeToFull();
        break;
      case SettingsSizeEnum.Normal:
        this.settingsService.changeSizeToNormal();
        break;
      default:
        this.setSizeToDefault(appSettings);
    }
  }

  private applyTemplateSettings(appSettings: AppSettings): void {
    switch (appSettings.formsTemplate) {
      case FormTemplateEnum.Default:
        this.settingsService.changeFormTemplateToDefault();
        break;
      case FormTemplateEnum.Horizontal:
        this.settingsService.changeFormTemplateToHorizontal();
        break;
      default:
        this.setTemplateToDefault(appSettings);
    }
  }

  private createDefaultSettings(): AppSettings {
    const appSettings: AppSettings = {
      size: SettingsSizeEnum.Full,
      formsTemplate: FormTemplateEnum.Default,
      focusMode: {
        isFocusMode: false,
      },
      version: environment.appSettingsVersion,
    };

    return appSettings;
  }

  private setSizeToDefault(appSettings: AppSettings): void {
    appSettings.size = SettingsSizeEnum.Full;
  }

  private setTemplateToDefault(appSettings: AppSettings): void {
    appSettings.formsTemplate = FormTemplateEnum.Default;
  }
}

export class AppSettings {
  size: SettingsSizeEnum;
  formsTemplate: FormTemplateEnum;
  focusMode: FocusModeSettings;
  version: number;
}

export class FocusModeSettings {
  isFocusMode: boolean;
}
