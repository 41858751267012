import { AbstractControl } from '@angular/forms';

type CompareFunction = (item1: any, item2: any) => boolean;
type LookupFunction = () => any[];

import { ValidationErrors, ValidatorFn } from '@angular/forms';

export function valueFromItemsOnly(
  itemsFn: LookupFunction | any[],
  compareFn: CompareFunction
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedValue = control.value;

    const itemList = itemsFn instanceof Function ? itemsFn() : itemsFn;

    ///prevents the extra check caused by bg-ng-dropdown [ngModel]!
    if (itemList == undefined) {
      return null;
    }

    if (
      itemList &&
      itemList.length &&
      itemList.some((item) => compareFn(item, selectedValue))
    ) {
      return null;
    } else {
      return { valueFromItems: true };
    }
  };
}
