export const environment = {
  production: true,
  enableServiceWorkerModule: false,
  domainURL: 'https://stg.eservices.moccae.gov.ae',
  hostURL: 'https://stg-gateway.apim.moccae.gov.ae/',
  eServiceAPIHost:
    'https://stg-gateway.apim.moccae.gov.ae/EServiceAPI/1/DigitalServices/',
  DigitalServicesHostURL:
    'https://stg-gateway.apim.moccae.gov.ae/DigitalServicesAPI/v1/api/',
  hostAnimalCare: 'animalcare/1/',
  hostCustomer: 'customer/1/',
  hostCommon: 'common/1/',
  environment_name: 'staging',
  reportingHostURL:
    'https://stg-gateway.apim.moccae.gov.ae/DSReportAPI/v1/api/',
  fileServerURL: 'https://ds-boffice.ds.moccae.gov.ae:4443/files/',
  sharedFilesServiceURL : 'https://files.ds.moccae.gov.ae/files/',

  hostInspectionsURL:
    'https://stg-gateway.apim.moccae.gov.ae/InspectionAPI/1/DigitalServices/',

  chantBotURL:
    'https://chatbot.moccae.gov.ae/assets/modules/channel-web/inject.js',
  customerPulseURL:
    'https://sandboxsurvey.customerpulse.gov.ae/destination/index.js',
  chantBotHostURL: 'https://chatbot.moccae.gov.ae',
  chatBotScript: 'assets/scripts/chatbot-stag.js',
  chatBotEnabled: false,

  hostPortalURL: 'https://stg-gateway.apim.moccae.gov.ae/WebsiteApi/1?',
  loginURL: 'https://stg.eservices.moccae.gov.ae/oauth.aspx',
  parentCookieDomainMOCCae: 'moccae.gov.ae',
  portalSearchUrl: 'https://www.moccae.gov.ae/Handlers/RESTApi.ashx',
  logoutUrl: 'https://stg.eservices.moccae.gov.ae/Logout.aspx',
  development: false,
  editProfileUrl:
    'https://stg.eservices.moccae.gov.ae/Account/EditProfile.aspx',
  cacheBusterHash: '2.0',
  esMerchantUrl: 'https://stg.ds.moccae.gov.ae/confirmation-page-es',
  googleMapConfigs: {
    APIKey: 'AIzaSyDI79DGydjSQpHgk4IIpvV4fWt6U47_N-8',
    defaultLatitude: 24.453884,
    defaultLongitude: 54.3773594,
    defaultZoom: 15,
    defaultRadius: 300,
    defaultCircleColor: 'blue',
    defaultAddress: 'F93G+HW5 - Al Manhal - Abu Dhabi - United Arab Emirates',
  },
  formats: {
    EMIRATES_ID: /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/,
  },
  gatewayPermanentToken: 'only for dev',
  appSettingsVersion: 1,
  downloadFileLink: 'FileManagement/DownloadFile.aspx?FileID={fileId}',
  ipInfoUrl: "https://ipinfo.io/json?token=19e1f6e68b1737"
};
