<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger': isShowErrors,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <input
      [id]="identifier"
      [name]="name"
      class="form-control"
      [ngClass]="hasErrors ? 'is-invalid' : touched ? 'is-valid' : ''"
      [type]="type"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      #control="ngModel"
      [required]="required == 'true' || required == '' ? '' : undefined"
      (blur)="onTouched()"
      [readonly]="readonly"
      [disabled]="disabled"
      [min]="min"
    />
    <small
      [id]="identifier + 'Help'"
      *ngIf="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}</small
    >

    <ng-container *ngTemplateOutlet="feedback"> </ng-container>
  </div>
</ng-template>

<ng-template #horizontal>
  <div
    class="form-group row"
    [ngClass]="{
      'has-error has-danger': isShowErrors,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <div class="col-sm-9">
      <input
        [id]="identifier"
        [name]="name"
        class="form-control"
        [ngClass]="hasErrors ? 'is-invalid' : touched ? 'is-valid' : ''"
        [type]="type"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        #control="ngModel"
        [required]="required == 'true' || required == '' ? '' : undefined"
        (blur)="onTouched()"
        [readonly]="readonly"
        [disabled]="disabled"
        [min]="min"
      />
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >

      <ng-container *ngTemplateOutlet="feedback"> </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #feedback>
  <ng-container *ngIf="isShowErrors">
    <div
      class="invalid-feedback"
      *ngFor="let error of errors; trackBy: trackByMessageKey"
      [innerText]="key + '.' + error.messageKey | translate : error.messageArgs"
    ></div>
  </ng-container>
</ng-template>
