import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HomeComponent } from './dashboard/home/home.component';
import { HealthCheckComponent } from './shared/health-check/health-check.component';
import { AuthGuard } from './shared/authentication/auth-guard.guard';
import { LaunchingDigitalServicesComponent } from './services/launching-digital-services/launching-digital-services.component';
import { OutstandingPaymentComponent } from './shared/outstanding-payment/outstanding-payment.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const approutes: Routes =
  [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'error', component: ErrorPageComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },

    { path: 'healthCheck', component: HealthCheckComponent },

    { path: 'services/launching-digital-services', component: LaunchingDigitalServicesComponent },
    { path: 'outstanding-payment', component: OutstandingPaymentComponent },

    {
      path: 'services', loadChildren: () => import('./services-search/services-search.module')
      .then(mod => mod.ServicesSearchModule
      ), canActivate: [AuthGuard]
    },
    {
      path: 'services/PractisingAnimalCareActivity', loadChildren: () => import('./services/practising-animal-care-activity/practising-animal-care-activity.module')
      .then(mod => mod.PractisingAnimalCareActivityModule
      ), canActivate: [AuthGuard]
    },
    {//procedureId: 167
      path: 'services/ImportAnimalProducts',
      loadChildren: () => import('./services/import/import-animal-products/import-animal-products.module').then(mod => mod.ImportAnimalProductsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 169
      path: 'services/ImportAnimalBirds',
      loadChildren: () => import('./services/import/import-animal-birds/import-animal-birds.module').then(mod => mod.ImportAnimalBirdsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAnimalPets',
      loadChildren: () => import('./services/import/import-animal-pets/import-animal-pets.module').then(mod => mod.ImportAnimalPetsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 175
      path: 'services/ImportAnimalSemenEmbryos',
      loadChildren: () => import('./services/import/import-animal-semen-embryos/import-animal-semen-embryos.module').then(mod => mod.ImportAnimalSemenEmbryosModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportFishingTools',
      loadChildren: () => import('./services/import/import-fishing-tools/import-fishing-tools.module').then(mod => mod.ImportFishingToolsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportVeterinaryRawMaterials',
      loadChildren: () => import('./services/import/import-veterinary-raw-materials/import-veterinary-raw-materials.module').then(mod => mod.ImportVeterinaryRawMaterialsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportVeterinaryEquipment',
      loadChildren: () => import('./services/import/import-veterinary-equipment/import-veterinary-equipment.module').then(mod => mod.ImportVeterinaryEquipmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 402
      path: 'services/ImportAnimalFeeds',
      loadChildren: () => import('./services/import/import-animal-feeds/import-animal-feeds.module').then(mod => mod.ImportAnimalFeedsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAgricultureConsignment',
      loadChildren: () => import('./services/import/import-agriculture-consignment/import-agriculture-consignment.module').then(mod => mod.ImportAgricultureConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAgricultureUnrestrictedFertilizers',
      loadChildren: () => import('./services/import/import-agriculture-unrestricted-fertilizers/import-agriculture-unrestricted-fertilizers.module').then(mod => mod.ImportAgricultureUnrestrictedFertilizersModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportPesticidesConsignment',
      loadChildren: () => import('./services/import/import-pesticides-consignment/import-pesticides-consignment.module').then(mod => mod.ImportPesticidesConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAgricultureRestrictedFertilizers',
      loadChildren: () => import('./services/import/import-agriculture-restricted-fertilizers/import-agriculture-restricted-fertilizers.module').then(mod => mod.ImportAgricultureRestrictedFertilizersModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportPheromonesConsignment',
      loadChildren: () => import('./services/import/import-pheromones-consignment/import-pheromones-consignment.module').then(mod => mod.ImportPheromonesConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportLiveFish',
      loadChildren: () => import('./services/import/import-live-fish/import-live-fish.module').then(mod => mod.ImportLiveFishModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 331
      path: 'services/ImportOzoneDepletingDrain',
      loadChildren: () => import('./services/import/import-ozone-depleting-drain/import-ozone-depleting-drain.module').then(mod => mod.ImportOzoneDepletingDrainModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAnimalHorses',
      loadChildren: () => import('./services/import/import-animal-horses/import-animal-horses.module').then(mod => mod.ImportAnimalHorsesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ImportAnimalSamples',
      loadChildren: () => import('./services/import/import-animal-samples/import-animal-samples.module').then(mod => mod.ImportAnimalSamplesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalBirds',
      loadChildren: () => import('./services/release/release-animal-birds/release-animal-birds.module').then(mod => mod.ReleaseAnimalBirdsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalProducts',
      loadChildren: () => import('./services/release/release-animal-products/release-animal-products.module').then(mod => mod.ReleaseAnimalProductsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureConsignments',
      loadChildren: () => import('./services/release/release-agriculture-consignment/release-agriculture-consignment.module').then(mod => mod.ReleaseAgricultureConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureRestrictedFertilizers',
      loadChildren: () => import('./services/release/release-agriculture-restricted-fertilizers/release-agriculture-restricted-fertilizers.module').then(mod => mod.ReleaseAgricultureRestrictedFertilizersModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureUnrestrictedFertilizers',
      loadChildren: () => import('./services/release/release-agriculture-unrestricted-fertilizers/release-agriculture-unrestricted-fertilizers.module').then(mod => mod.ReleaseAgricultureUnrestrictedFertilizersModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalFeeds',
      loadChildren: () => import('./services/release/release-animal-feeds/release-animal-feeds.module').then(mod => mod.ReleaseAnimalFeedsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalHorses',
      loadChildren: () => import('./services/release/release-animal-horses/release-animal-horses.module').then(mod => mod.ReleaseAnimalHorsesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalSemenEmbryos',
      loadChildren: () => import('./services/release/release-animal-semen-embryos/release-animal-semen-embryos.module').then(mod => mod.ReleaseAnimalSemenEmbryosModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseCitesSpecies',
      loadChildren: () => import('./services/release/release-cites-species/release-cites-species.module').then(mod => mod.ReleaseCitesSpeciesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseFishingTools',
      loadChildren: () => import('./services/release/release-fishing-tools/release-fishing-tools.module').then(mod => mod.ReleaseFishingToolsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseLiveFish',
      loadChildren: () => import('./services/release/release-live-fish/release-live-fish.module').then(mod => mod.ReleaseLiveFishModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseOzoneDepletingDrain',
      loadChildren: () => import('./services/release/release-ozone-depleting-drain/release-ozone-depleting-drain.module').then(mod => mod.ReleaseOzoneDepletingDrainModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleasePesticidesConsignment',
      loadChildren: () => import('./services/release/release-pesticides-consignment/release-pesticides-consignment.module').then(mod => mod.ReleasePesticidesConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleasePheromonesConsignment',
      loadChildren: () => import('./services/release/release-pheromones-consignments/release-pheromones-consignments.module').then(mod => mod.ReleasePheromonesConsignmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseVeterinaryRawMaterials',
      loadChildren: () => import('./services/release/release-veterinary-raw-materials/release-veterinary-raw-materials.module').then(mod => mod.ReleaseVeterinaryRawMaterialsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseVeterinaryEquipment',
      loadChildren: () => import('./services/release/release-veterinary-equipment/release-veterinary-equipment.module').then(mod => mod.ReleaseVeterinaryEquipmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalPets',
      loadChildren: () => import('./services/release/release-animal-pets/release-animal-pets.module').then(mod => mod.ReleaseAnimalPetsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureConsignmentBees',
      loadChildren: () => import('./services/release/release-agriculture-consignment-bees/release-agriculture-consignment-bees.module').then(mod => mod.ReleaseAgricultureConsignmentBeesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureConsignmentPlants',
      loadChildren: () => import('./services/release/release-agriculture-consignment-plants/release-agriculture-consignment-plants.module').then(mod => mod.ReleaseAgricultureConsignmentPlantsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureConsignmentSeeds',
      loadChildren: () => import('./services/release/release-agriculture-consignment-seeds/release-agriculture-consignment-seeds.module').then(mod => mod.ReleaseAgricultureConsignmentSeedsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAgricultureImportedProducts',
      loadChildren: () => import('./services/release/release-agriculture-imported-products/release-agriculture-imported-products.module').then(mod => mod.ReleaseAgricultureImportedProductsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ReleaseAnimalSamples',
      loadChildren: () => import('./services/release/release-animal-samples/release-animal-samples.module').then(mod => mod.ReleaseAnimalSamplesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportPhytosanitaryCertificate',
      loadChildren: () => import('./services/export/export-phytosanitary-certificate/export-phytosanitary-certificate.module').then(mod => mod.ExportPhytosanitaryCertificateModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportLocalAquatic',
      loadChildren: () => import('./services/export/export-local-aquatic/export-local-aquatic.module').then(mod => mod.ExportLocalAquaticModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportVeterinaryLiveAnimals',
      loadChildren: () => import('./services/export/export-veterinary-live-animals/export-veterinary-live-animals.module').then(mod => mod.ExportVeterinaryLiveAnimalsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportVeterinaryAnimalsFishFeed',
      loadChildren: () => import('./services/export/export-veterinary-animals-fish-feed/export-veterinary-animals-fish-feed.module').then(mod => mod.ExportVeterinaryAnimalsFishFeedModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportAquaticProducts',
      loadChildren: () => import('./services/export/export-aquatic-products/export-aquatic-products.module').then(mod => mod.ExportAquaticProductsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportCitesSpecies',
      loadChildren: () => import('./services/export/export-cites-species/export-cites-species.module').then(mod => mod.ExportCitesSpeciesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportHazardousWaste',
      loadChildren: () => import('./services/export/export-hazardous-waste/export-hazardous-waste.module').then(mod => mod.ExportHazardousWasteModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportAnimalSamples',
      loadChildren: () => import('./services/export/export-animal-samples/export-animal-samples.module').then(mod => mod.ExportAnimalSamplesModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportAgriculturalFeed',
      loadChildren: () => import('./services/export/export-agricultural-feed/export-ag-feed.module').then(mod => mod.ExportAgFeedModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/TransshipmentOrTransitPermitForAnimals',
      loadChildren: () => import('./services/export/transit-live-animals/transit-live-animals.module').then(mod => mod.TransitLiveAnimalsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/AccreditExportingFoodEstablishment',
      loadChildren: () => import('./services/accreditation/accredit-exporting-food-establishment/accredit-exporting-food-establishment.module').then(mod => mod.AccreditExportingFoodEstablishmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {
      path: 'services/ExportHCFC',
      loadChildren: () => import('./services/export/export-hcfc/export-hcfc.module').then(mod => mod.ExportHCFCModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 433
      path: 'services/ExportVeterinaryProducts',
      loadChildren: () => import('./services/export/export-veterinary-products/export-veterinary-products.module').then(mod => mod.ExportVeterinaryProductsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {// اعتماد وحدة معاملة أخشاب التعبئة
      path: 'services/WoodProcessing', loadChildren: () => import('./services/wood-processing/wood-processing.module')
      .then(mod => mod.WoodprocessingModule
      ), canActivate: [AuthGuard]
    },
    {
      path: 'services/AquacultureFarm', loadChildren: () => import('./services/aquaculture-farm/aquaculture-farm.module')
      .then(mod => mod.AquacultureFarmModule
      ), canActivate: [AuthGuard]
    },
    {
      path: 'services/AquaticOrganisms', loadChildren: () => import('./services/aquatic-organisms/aquatic-organisms.module')
      .then(mod => mod.AquaticOrganismsModule
      ), canActivate: [AuthGuard]
    },
    {
      path: 'services/Agriculture', loadChildren: () => import('./services/agriculture/agriculture.module')
      .then(mod => mod.AgricultureModule
      ), canActivate: [AuthGuard]
    },
    {//procedureId: 388
      path: 'services/AWDManifactureGMP',
      loadChildren: () => import('./services/accreditation/accredit-awd-manifacture-gmp/accredit-gmp.module').then(mod => mod.AccreditGMPModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 149
      path: 'services/CitesNoobjLetter',
      loadChildren: () => import('./services/accreditation/accredit-cities-no-obj/accredit-cites-no-obj.module').then(mod => mod.AccreditCITESNoObjModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 404
      path: 'services/AccreditationPheromonesAttractantsRepellents',
      loadChildren: () => import('./services/accreditation/accredit-pheromones-attractants-repellents/accredit-pheromones-attractants-repellents.module').then(mod => mod.AccreditPheromonesAttractantsRepellentsModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 395
      path: 'services/AccreditationVeterinaryEquipment',
      loadChildren: () => import('./services/accreditation/accredit-veterinary-equipment/accredit-veterinary-equipment.module').then(mod => mod.AccreditVeterinaryEquipmentModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 390
      path: 'services/AccreditVeterinarySell',
      loadChildren: () => import('./services/accreditation/accredit-veterinary-sell/accredit-veterinary-sell.module').then(mod => mod.AccreditVeterinarySellModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 393
      path: 'services/APHVetProducts',
      loadChildren: () => import('./services/accreditation/accredit-animal-feed/accredit-animal-feed.module').then(mod => mod.AccreditAnimalFeedModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 394
      path: 'services/APHVetRenewProducts',
      loadChildren: () => import('./services/accreditation/accredit-renew-animal-feed/accredit-renew-animal-feed.module').then(mod => mod.AccreditRenewAnimalFeedModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 381
      path: 'services/RegisterVETProdCO',
      loadChildren: () => import('./services/registration/register-vet-prod-co/register-vet-prod-co.module').then(mod => mod.RegisterVETProdCOModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 382
      path: 'services/RegisterRenewVETProdCO',
      loadChildren: () => import('./services/registration/register-renew-vet-prod-co/register-renew-vet-prod-co.module').then(mod => mod.RegisterRenewVETProdCOModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 136
      path: 'services/AccreditSlaughterHouse',
      loadChildren: () => import('./services/accreditation/accredit-slaughter-house/accredit-slaughter-house.module').then(mod => mod.AccreditSlaughterHouseModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 202
      path: 'services/AccreditRenewSlaughterHouse',
      loadChildren: () => import('./services/accreditation/accredit-renew-slaughter-house/accredit-renew-slaughter-house.module').then(mod => mod.AccreditRenewSlaughterHouseModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 385
      path: 'services/AccreditRawMaterial',
      loadChildren: () => import('./services/accreditation/accredit-raw-material/accredit-raw-material.module').then(mod => mod.AccreditRawMaterialModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 379
      path: 'services/AccreditVetProduct',
      loadChildren: () => import('./services/accreditation/accredit-vet-product/accredit-vet-product.module').then(mod => mod.AccreditVetProductModule),
      canActivate: [AuthGuard],
      data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 380
      path: 'services/AccreditRenewVetProduct',
      loadChildren: () => import('./services/accreditation/accredit-renew-vet-product/accredit-renew-vet-product.module').then(mod => mod.AccreditRenewVetProductModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 111
      path: 'services/AccreditLocalFertilizer',
      loadChildren: () => import('./services/accreditation/accredit-local-fertilizer/accredit-local-fertilizer.module').then(mod => mod.AccreditLocalFertilizerModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 396
      path: 'services/ImportVeterinaryProduct',
      loadChildren: () => import('./services/import/import-veterinary-product/import-veterinary-product.module').then(mod => mod.ImportVeterinaryProductModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 397
      path: 'services/ReleaseVeterinaryProduct',
      loadChildren: () => import('./services/release/release-veterinary-product/release-veterinary-product.module').then(mod => mod.ReleaseVeterinaryProductModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 430
      path: 'services/ReleaseFalcon',
      loadChildren: () => import('./services/release/release-falcon/release-falcon.module').then(mod => mod.ReleaseFalconModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 427
      path: 'services/AccreditPlanetProtection',
      loadChildren: () => import('./services/accreditation/accredit-planet-protection/accredit-planet-protection.module').then(mod => mod.AccreditPlanetProtectionModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 431
      path: 'services/ExportFertilizer',
      loadChildren: () => import('./services/export/export-fertilizer/export-fertilizer.module').then(mod => mod.ExportFertilizerModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 432
      path: 'services/ExportPesticide',
      loadChildren: () => import('./services/export/export-pesticide/export-pesticide.module').then(mod => mod.ExportPesticideModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 107
      path: 'services/RegisterFertilizer',
      loadChildren: () => import('./services/registration/register-fertilizers/register-fertilizer.module').then(mod => mod.RegisterFertilizerModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 140
      path: 'services/RegisterPesticides',
      loadChildren: () => import('./services/registration/register-pesticides/register-pesticides.module').then(mod => mod.RegisterPesticidesModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 363
      path: 'services/CitesTransferOwnership',
      loadChildren: () => import('./services/cites/cites-transfer-ownership/cites-transfer-ownership.module').then(mod => mod.CitesTransferOwnershipModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 358
      path: 'services/CitesCancelOwnership',
      loadChildren: () => import('./services/cites/cites-cancel-ownership/cites-cancel-ownership.module').then(mod => mod.CitesCancelOwnershipModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 344
      path: 'services/RegisterCitesSpecies',
      loadChildren: () => import('./services/cites/cites-register-species/cites-register-species.module').then(mod => mod.CitesRegisterSpeciesModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 137
      path: 'services/CitesFalconRings',
      loadChildren: () => import('./services/cites/cites-falcon-rings/cites-falcon-rings.module').then(mod => mod.CitesFalconRingsModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 252
      path: 'services/CitesFalconPassports',
      loadChildren: () => import('./services/cites/cites-falcon-passports/cites-falcon-passports.module').then(mod => mod.CitesFalconPassportsModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 423
      path: 'services/LicenseVeterinaryFacility',
      loadChildren: () => import('./services/license/license-veterinary-facility/license-veterinary-facility.module').then(mod => mod.LicenseVeterinaryFacilityModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 77
      path: 'services/LicenseProfessionVeterinarian',
      loadChildren: () => import('./services/license/license-profession-veterinarian/license-profession-veterinarian.module').then(mod => mod.LicenseProfessionVeterinarianModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 10
      path: 'services/LicenseTemporaryVeterinarian',
      loadChildren: () => import('./services/license/license-temporary-veterinarian/license-temporary-veterinarian.module').then(mod => mod.LicenseTemporaryVeterinarianModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 375
      path: 'services/LicenseEnvironmentComply',
      loadChildren: () => import('./services/license/license-environment-comply/license-environment-comply.module').then(mod => mod.LicenseEnvironmentComplyModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 368
      path: 'services/CitesExportSpecies',
      loadChildren: () => import('./services/cites/cites-export-species/cites-export-species.module').then(mod => mod.CitesExportSpeciesModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 364
      path: 'services/CitesImportSpecies',
      loadChildren: () => import('./services/cites/cites-import-species/cites-import-species.module').then(mod => mod.CitesImportSpeciesModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 37
      path: 'services/FarmersAgricultureGuidance',
      loadChildren: () => import('./services/farmers/farmers-agriculture-guidance/farmers-agriculture-guidance.module').then(mod => mod.FarmersAgricultureGuidanceModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 410
      path: 'services/FarmersAnimalPossession',
      loadChildren: () => import('./services/farmers/farmers-animal-possession/farmers-animal-possession.module').then(mod => mod.FarmersAnimalPossessionModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 32
      path: 'services/FarmersVeterinaryGuidance',
      loadChildren: () => import('./services/farmers/farmers-veterinary-guidance/farmers-veterinary-guidance.module').then(mod => mod.FarmersVeterinaryGuidanceModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 231
      path: 'services/FishersBoatLicense',
      loadChildren: () => import('./services/fishers/fishers-boat-license/fishers-boat-license.module').then(mod => mod.FishersBoatLicenseModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 272
      path: 'services/FishersBoatLicenseRenew',
      loadChildren: () => import('./services/fishers/fishers-boat-license-renew/fishers-boat-license-renew.module').then(mod => mod.FishersBoatLicenseRenewModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 254
      path: 'services/FishersBoatEngine',
      loadChildren: () => import('./services/fishers/fishers-boat-engine/fishers-boat-engine.module').then(mod => mod.FishersBoatEngineModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 280
      path: 'services/FishersBoatInheritors',
      loadChildren: () => import('./services/fishers/fishers-boat-inheritors/fishers-boat-inheritors.module').then(mod => mod.FishersBoatInheritorsModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 305
      path: 'services/FishersBoatReplace',
      loadChildren: () => import('./services/fishers/fishers-boat-replace/fishers-boat-replace.module').then(mod => mod.FishersBoatReplaceModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 283
      path: 'services/FishersBoatSale',
      loadChildren: () => import('./services/fishers/fishers-boat-sale/fishers-boat-sale.module').then(mod => mod.FishersBoatSaleModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 321
      path: 'services/FishersBoatChangeFishing',
      loadChildren: () => import('./services/fishers/fishers-boat-change-fishing/fishers-boat-change-fishing.module').then(mod => mod.FishersBoatChangeFishingModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 237
      path: 'services/FishersViseCaptainLicense',
      loadChildren: () => import('./services/fishers/fishers-vise-captain-license/FishersViseCaptainLicense.module').then(mod => mod.FishersViseCaptainLicenseModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 312
      path: 'services/FishersViseCaptainLicenseRenew',
      loadChildren: () => import('./services/fishers/fishers-vise-captain-license-renew/FishersViseCaptainLicenseRenew.module').then(mod => mod.FishersViseCaptainLicenseRenewModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 369
      path: 'services/CitesReleaseSpecies',
      loadChildren: () => import('./services/cites/cites-release-species/cites-release-species.module').then(mod => mod.CitesReleaseSpeciesModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 43
      path: 'services/LicenseAgricultureEngineer',
      loadChildren: () => import('./services/license/license-agriculture-engineer/license-agriculture-eng.module').then(mod => mod.LicenseAgricultureEngModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 73
      path: 'services/RegisterAgricultureHolding',
      loadChildren: () => import('./services/registration/register-agriculture-holding/register-agriculture-holding.module').then(mod => mod.RegisterAgricultureHoldingModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 270
      path: 'services/FishersBoatTransferOwnership',
      loadChildren: () => import('./services/fishers/fishers-boat-transfer-ownership/fishers-boat-transfer-ownership.module').then(mod => mod.FishersBoatTransferOwnershipModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 80
      path: 'services/SupportingMaterial',
      loadChildren: () => import('./services/supporting-materials/supporting-materials-request/supporting-materials-request.module').then(mod => mod.SupportingMaterialsRequestModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    {//procedureId: 409
      path: 'services/CaseStudy',
      loadChildren: () => import('./services/supporting-materials/supporting-materials-case-study/supporting-materials-case-study.module').then(mod => mod.SupportingMaterialsCaseStudyModule),
        canActivate: [AuthGuard],
        data: { breadcrumb: { labelKey: 'SERVICES', url: 'services'} },
    },
    { path: 'confirmation-page', loadChildren: () => import('./shared/confirmation-page/confirmation-page.module').then(m => m.ConfirmationPageModule) },
    { path: 'confirmation-page-es', loadChildren: () => import('./shared/es-confirmation-page/es-confirmation-page.module').then(m => m.EsConfirmationPageModule) },
    { path: 'es-receipt', loadChildren: () => import('./services/es-shared/es-receipt/es-receipt.module').then(m => m.EsReceiptModule), canActivate: [AuthGuard] },
    { path: 'services/serviceAccessibility', loadChildren: () => import('./shared/es-service-accessibility/es-service-accessibility.module').then(m => m.EsServiceAccessibilityPageEsModule), canActivate: [AuthGuard] },
    { path: 'view-paid-payment-requests', loadChildren: () => import('./shared/view-paid-payment-requests/view-paid-payment-requests.module').then(m => m.ViewPaidPaymentRequestsModule), canActivate: [AuthGuard] },
    {
      path: 'generic-service-view',
      loadChildren: () => import('./services-views/generic-service-view/generic-service-view.module').then(m => m.GenericServiceViewModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'ui-snippets',
      loadChildren: () => import('./shared/ui-snippets/ui-snippets.module').then(m => m.UiSnippetsModule),
    },
    { path: 'view-inspection-data', loadChildren: () => import('./services-views/view-inspection-data/view-inspection-data.module').then(m => m.ViewInspectionDataModule), canActivate: [AuthGuard] },
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(approutes, { scrollPositionRestoration: 'top' }),
    BrowserModule,
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [],
  bootstrap: [],
})
export class AppRoutingModule {}
