import { Component, Injector, Input } from '@angular/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { TranslateService } from '@ngx-translate/core';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';

@Component({
  selector: 'bs-ng-number',
  templateUrl: './bs-ng-number.component.html',
  providers: [MakeProvider(BsNgNumberComponent)],
})
export class BsNgNumberComponent extends BsNgInputBaseComponent {
  @Input()
  onlyPositive: string | boolean = false;

  @Input() onlyPositiveErrorMessage: string;
  @Input() maxErrorMessage: string;
  @Input() minErrorMessage: string;
  @Input() moreThanNumberErrorMessage: string;
  @Input() notIntegerErrorMessage: string;

  get isOnlyPositive(): boolean {
    return this.onlyPositive === true || this.onlyPositive == '';
  }

  @Input()
  min: number;

  public get minIfOnlyPositive(): number | string {
    if (this.isOnlyPositive) {
      return 0;
    }

    return '';
  }

  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  hasMaxError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      !this.ngControl.control.errors.required &&
      this.ngControl.control.errors.max
    );
  }

  public get hasMinError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      !this.ngControl.control.errors.required &&
      this.ngControl.control.errors.min
    );
  }

  hasOnlyPositive(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.onlyPositive
    );
  }

  hasMoreThanNumberError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.moreThanNumber
    );
  }

  public get hasNotIntegerError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.notInteger
    );
  }

  protected assignTranslations(trs: any) {
    super.assignTranslations(trs);
    this.onlyPositiveErrorMessage = trs.onlyPositiveErrorMessage || '';
    this.moreThanNumberErrorMessage = trs.moreThanNumberErrorMessage || '';
    this.notIntegerErrorMessage = trs.notIntegerErrorMessage || '';
    this.maxErrorMessage = trs.maxErrorMessage;
    this.minErrorMessage = trs.minErrorMessage;
    this.customErrorMessage = trs.customErrorMessage;
  }

  hasCustomError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.customError
    );
  }
}
