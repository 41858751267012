import { FileAttachment } from './FileAttachment';

export class RequestAttachment {
  serviceRequestTransactionId: number;
  serviceCatalogId: number;
  attachmentID: number;

  fileAttachmentId: number;
  fileAttachment: FileAttachment;

  isNewMessage: boolean;
  employeeFeedbackMessage: string;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  attachment: AttachmentLkp;
}
export interface AttachmentLkp {
  attachmentCode: string;
  nameAr: string;
  nameEn: string;
  isActive: boolean;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}
