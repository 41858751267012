import {
  Input,
  Component,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { AttachmentFileDetails } from 'src/app/core/models/common/AttachmentFormObject';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'ds-service-attachments',
  templateUrl: './ds-service-attachments.component.html',
})
export class DsServiceAttachmentsComponent<T>
  extends BaseComponent
  implements OnChanges
{
  showValidation: boolean;
  readonly MAX_COUNT_OF_ATTACHMENTS = 1;
  readonly MAX_FILE_SIZE_BYTE: number = 3 * 1024 * 1024; ///3MB

  @Input()
  attachments: IDsServiceAttachment[] = [];

  @Output()
  fileSelected = new EventEmitter();

  @Output()
  remove = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    translateService: TranslateService,
    private toaster: ToastrService
  ) {
    super(translateService);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  public onSelectFile(
    event: NgxFileDropEntry[],
    attachment: IDsServiceAttachment
  ): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.validateFileSize(file)) {
            this.emitSelectFile(file, attachment);
          } else {
            this.toaster.error(
              this.translateService.instant('trMaximumAllowedFileSize', {
                maximumAllowedFileSize:
                  this.convertByteToMegaByte(this.MAX_FILE_SIZE_BYTE) + ' MB',
              })
            );
          }
        });
      }
    }
  }

  private convertByteToMegaByte(sizeInByte: number) {
    if (sizeInByte <= 0) {
      return 0;
    }

    return sizeInByte / (1024 * 1024);
  }

  private validateFileSize(file: File) {
    return file.size <= this.MAX_FILE_SIZE_BYTE;
  }

  private emitSelectFile(file: File, attachment: IDsServiceAttachment) {
    this.fileSelected.emit({
      file: file,
      attachment: attachment,
    });
  }

  public removeFileInstance(
    attachment: IDsServiceAttachment,
    instance: AttachmentFileDetails
  ) {
    this.remove.emit({
      instance: instance,
    });
  }

  public onUploadButtonClicked(func: any): void {
    func();
  }

  public link(file: AttachmentFileDetails) {
    return file.filePath;
  }
}

export interface IDsServiceAttachment {
  nameAr: string;
  nameEn: string;
  isRequired: boolean;

  ///es class used here as a start point to unify
  instances?: AttachmentFileDetails[];

  identity: string | number;

  helpText?: string;
}
