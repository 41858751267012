<div class="ds-card ds-card-default ds-card-has-icon ds-card-service" [@rotateShake]>
  <div class="row">
    <div class="col-sm-12">
      <h5
        class="ds-card-title text-primary"
        [innerText]="service.serviceDetails.title"
      ></h5>
      <p
        class="card-text"
        [innerText]="service.serviceDetails.mainCategoryTitle"
      ></p>
      <small
        class="text-muted"
        [innerHTML]="service.serviceDetails.serviceTime"
      ></small>

      <ng-container *ngIf="service.expanded && service.metadata">
        <h6
          class="ds-card-title text-primary mt-3"
          [innerText]="'trTermsAndConditions' | translate"
        ></h6>
        <p class="card-text p-3">
          <app-rendering-html
            [dynamicHtml]="
              isArabic
                ? service.metadata.formattedTermsAndConditionsAr
                : service.metadata.formattedTermsAndConditionsEn
            "
          ></app-rendering-html>
        </p>
      </ng-container>

      <ng-container *ngIf="service.expanded && service.metadata">
        <h6
          class="ds-card-title text-primary mt-3"
          [innerText]="'ModalTitleFAQ' | translate"
        ></h6>
        <p class="card-text p-3">
          <app-rendering-html
            [dynamicHtml]="
              isArabic ? service.metadata.faqAr : service.metadata.faqEn
            "
          ></app-rendering-html>
        </p>
      </ng-container>
    </div>
    <div class="favorite-star">
      <a
        class="cursor-pointer"
        [class.text-yellow]="service.isFavorite"
        [class.text-secondary]="service.isFavorite == false"
      >
        <i
          class="fa fa-star fa-lg"
          aria-hidden="true"
          (click)="toggleFavorite(service)"
        ></i
      ></a>
    </div>
  </div>
  <div class="pt-1 d-flex justify-content-between">
    <div class="pr-0">
      <div class="ds-btns">
        <a
          [href]="service.serviceDetails.eServiceLink"
          class="ds-btn ds-btn-primary ds-btn-rounded ds-btn-small"
        >
          <span class="mr-2" [innerText]="'trStartService' | translate"></span>
          <i class="fa fa-play" aria-hidden="true"></i>
        </a>
        <button
          class="ds-btn ds-btn-secondary ds-btn-rounded ds-btn-small"
          *ngIf="service.expanded == false"
          (click)="fetchServiceMetadata(service)"
        >
          <span class="mr-2" [innerText]="'trMore' | translate"></span>
          <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
        </button>
        <button
          class="ds-btn ds-btn-secondary ds-btn-rounded ds-btn-small"
          *ngIf="service.expanded"
          (click)="collapse(service)"
        >
          <span class="mr-2" [innerText]="'trLess' | translate"></span>
          <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
        </button>
      </div>
    </div>
    <div class="text-right text-primary d-flex">
      <a class="align-self-center" [href]="service.serviceDetails.link">
        <span
          class="mr-2"
          [innerText]="'trViewFavoriteServiceDetails' | translate"
        ></span>
        <i class="fa fa-external-link" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>
