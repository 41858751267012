import {
  Component,
  Input,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { TranslateService } from '@ngx-translate/core';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';

@Component({
  selector: 'bs-ng-select',
  templateUrl: './bs-ng-select.component.html',
  providers: [MakeProvider(BsNgSelectComponent)],
})
export class BsNgSelectComponent extends BsNgInputBaseComponent {
  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  @Input() options: [any];
  @Input('option-text') optionText = 'text';
  @Input('option-value') optionValue = 'value';
  @Input('with-null') withNull = '';
  @Output() getSelected = new EventEmitter();

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  selectedChange($event: any) {
    let selectedIndex = $event.target.value.split(':')[0];

    if (selectedIndex) {
      selectedIndex = parseInt(selectedIndex) - (this.withNull ? 1 : 0);
      const selectedItem = this.options[selectedIndex];
      const selectedText = selectedItem
        ? selectedItem[this.optionText]
        : undefined;
      const selectedValue = selectedItem
        ? selectedItem[this.optionValue]
        : undefined;
      this.getSelected.emit({
        selected: selectedItem,
        value: selectedValue,
        text: selectedText,
      });
    } else {
      this.getSelected.emit({ selected: null, value: '', text: '' });
    }
  }
}
