import { Component, Injector, Input } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'bs-ng-phone',
  templateUrl: './bs-ng-phone.component.html',
  providers: [MakeProvider(BsNgPhoneComponent)],
})
export class BsNgPhoneComponent extends BsNgInputBaseComponent {
  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  @Input() phoneMessage = '';

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  protected assignTranslations(trs: any) {
    super.assignTranslations(trs);
    this.phoneMessage = trs.phoneMessage;
  }

  hasPhoneError() {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      (this.ngControl.control.errors.phone ||
        this.ngControl.control.errors.mobile)
    );
  }
}
