import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DashBoardDetailsService } from 'src/app/core/api-services/customer/dash-board-details.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/core/utilities/util.service';
import { RequestStatusCodesColors } from 'src/app/core/enums/request-status.codes.colors';
import { ServiceRequestTypeCodeEnum } from 'src/app/core/enums/service-request-type-code.enum';
import * as dashBoardDetails from '../../core/models/customer/DashBoardDetails';
import { Title } from '@angular/platform-browser';
import { PackageTypes } from '../../core/enums/package-types';
import {
  Employee,
  RegisteredUser,
  RegisteredUserTypesEnum,
} from '../../core/models/profile/RegisteredUser';
import {
  WorkflowActionsCodesEnum,
  WorkflowStepStatusCodesEnum,
} from '../../core/models/common/ServiceMainTransactionData';
import { GetCertificateDetailsService } from '../../core/api-services/customer/get-certificate-details.service';
import { FrontOfficeCardPackageEnum } from '../../core/models/customer/RegisteredUserCertificate';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { EsResponseModel } from 'src/app/core/api-services/shared/EsResponseModel';
import {
  ConfirmationRequestCodeEnum,
  EsUnconfirmedPoppedUpRequest,
  EsUnconfirmedPoppedUpRequestDto,
} from 'src/app/core/api-services/shared/EsUnconfirmedPoppedUpRequestDto';
import { ServicesListDataService } from '../../core/api-services/shared/services/ServicesListDataService';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  public noneConfirmedRequests: EsUnconfirmedPoppedUpRequest = null;

  constructor(
    private dashBoardDetailsService: DashBoardDetailsService,
    public utilService: UtilService,
    public route: ActivatedRoute,
    private titleService: Title,
    private certificateDetailService: GetCertificateDetailsService,
    private esCommonService: EsCommonService,
    private servicesListDataService: ServicesListDataService,
    translateService: TranslateService
  ) {
    super(translateService);

    this.parametersLoaded(this.route.snapshot.queryParamMap);

    this.requestStatusCodesColors = Object.assign({}, RequestStatusCodesColors);

    this.observableSubscriptions.push(
      translateService.onLangChange.subscribe(() => {
        this.servicesListDataService.loadFavoriteServicesHybrid(this.lang);
      })
    );

    this.window = window;

    this.route.queryParams.subscribe((params) => {
      if (params.isStatistics == 'true') {
        this.openDashboardStatistics();
      }

      if (params.isNotification == 'true') {
        // the will happen if there is existing Notification only. [count > 0]
        //TODO: refresh notifications
      }
    });
  }

  public ngOnInit(): void {
    this.getDashBoardDetails();
    this.employeeObject = this.utilService.getEmployeeProfile();
    this.getNoneConfirmedRequests();
  }

  private async parametersLoaded(params: ParamMap) {
    this.changeLanguage(params.get('lang'));
  }

  public get WorkflowStepStatusCodesEnum(): typeof WorkflowStepStatusCodesEnum {
    return WorkflowStepStatusCodesEnum;
  }

  public get RegisteredUserTypesEnum(): typeof RegisteredUserTypesEnum {
    return RegisteredUserTypesEnum;
  }

  get AnimalCareRequestTypeEnum(): typeof ServiceRequestTypeCodeEnum {
    return ServiceRequestTypeCodeEnum;
  }

  public get PackageTypeEnum(): typeof PackageTypes {
    return PackageTypes;
  }

  observableSubscriptions: Subscription[] = [];
  dashBoardDetailsObject: dashBoardDetails.DashBoardDetailsObject;
  dashBoardPackages: dashBoardDetails.Package[] = [];
  requestStatusCodesColors: any = {};
  showDetailClicked = false;
  showDashboardStatistics = false;
  showImportantLinks = false;
  currentSelectedPackageId: any;
  currentSelectedPackage: dashBoardDetails.Package;
  previous = '';
  window: any;

  noOfDaysWithoutRequest: any;
  employeeObject: Employee;
  DOMAIN_URL = environment.domainURL;
  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Pending On Payment',
      value: 'PendingOnPayment',
    },
    {
      name: 'Finished',
      value: 'Finished',
    },
  ];

  lastSubmittedActionCode: WorkflowActionsCodesEnum =
    WorkflowActionsCodesEnum.ReturnToCustomer;

  currentSelectedIsEndWorkFlowStep: boolean;

  hasLicense = false;

  ngOnDestroy(): void {}

  getCurrentClickedPackage(): dashBoardDetails.Package {
    let packageObject: dashBoardDetails.Package;
    packageObject = this.dashBoardPackages.find(
      (p) => p.ID == this.currentSelectedPackageId
    );

    return packageObject;
  }

  toggleServiceDetails(id: string) {
    this.closeDashboardStatistics();
    this.closeImportantLinks();
    if (this.showDetailClicked == true && this.currentSelectedPackageId == id) {
      this.closeServiceDetails();
    } else {
      this.showDetailClicked = true;
      this.currentSelectedPackageId = id;
      this.currentSelectedPackage = this.getCurrentClickedPackage();
    }
  }

  autoSelectLicensesForPracticingActivitiesPackageDetails() {
    this.showDetailClicked = true;
    this.currentSelectedPackageId =
      PackageTypes.LicensesForPracticingActivities;
    this.currentSelectedPackage = this.getCurrentClickedPackage();
  }

  closeServiceDetails() {
    this.currentSelectedPackageId = null;
    this.currentSelectedPackage = null;
    this.showDetailClicked = false;
  }

  closeDashboardStatistics = (): void => {
    this.showDashboardStatistics = false;
  };

  closeImportantLinks = (): void => {
    this.showImportantLinks = false;
  };

  openImportantLinks = (): void => {
    this.closeServiceDetails();
    this.closeDashboardStatistics();
    this.showImportantLinks = !this.showImportantLinks;
    if(this.showImportantLinks){
      setTimeout(() => {
        document.getElementById('important-link-cont').focus();
      });
    }
  };

  openDashboardStatistics() {
    this.closeServiceDetails();
    this.closeImportantLinks();
    if (this.showDashboardStatistics == false) {
      this.showDashboardStatistics = true;
    } else {
      this.showDashboardStatistics = false;
    }
  }

  getUserProfile(): RegisteredUser {
    return this.utilService.getUserProfile();
  }

  getDashBoardDetails() {
    this.observableSubscriptions.push(
      this.dashBoardDetailsService
        .getDashBoardDetails(this.utilService.getParticipantId())
        .subscribe((response: dashBoardDetails.DashBoardDetailsObject) => {
          this.dashBoardDetailsObject = response;
          if (this.dashBoardDetailsObject.Data) {
            if (
              this.dashBoardDetailsObject &&
              this.dashBoardDetailsObject.Data
            ) {
              this.dashBoardPackages =
                this.dashBoardDetailsObject.Data.Packages;

              //############ Set DS Certificates Count #####################
              this.setDSCertificatesCount(this.dashBoardPackages);

              this.noOfDaysWithoutRequest =
                this.differenceBetweenLastRequestDateAndCurrentDateInHours();
            }
          }
        })
    );
  }

  setDSCertificatesCount(packages: dashBoardDetails.Package[]) {
    const licensesForPracticingActivitiesPackage = packages.find(
      (p) => p.ID == PackageTypes.LicensesForPracticingActivities
    );
    const licensesForPracticingProfessionalPackage = packages.find(
      (p) => p.ID == PackageTypes.MyLicensesForPracticingProfessional
    );

    if (licensesForPracticingActivitiesPackage != null) {
      this.certificateDetailService
        .GetCertificateDetailsCount(
          FrontOfficeCardPackageEnum.MyLicensesForPracticingActivities
        )
        .subscribe((res) => {
          if (licensesForPracticingActivitiesPackage != null) {
            licensesForPracticingActivitiesPackage.AboutToExpireLicensesCount +=
              res.responseData?.isAboutToExpiredCertificatesCount;
            licensesForPracticingActivitiesPackage.ActiveLicensesCount +=
              res.responseData?.activeCertificatesCount;
            licensesForPracticingActivitiesPackage.ExpiredLicensesCount +=
              res.responseData?.expiredCertificatesCount;
          }
        });
    }

    if (licensesForPracticingProfessionalPackage != null) {
      this.certificateDetailService
        .GetCertificateDetailsCount(
          FrontOfficeCardPackageEnum.MyLicensesForPracticingProfessional
        )
        .subscribe((res) => {
          if (licensesForPracticingProfessionalPackage != null) {
            licensesForPracticingProfessionalPackage.AboutToExpireLicensesCount +=
              res.responseData?.isAboutToExpiredCertificatesCount;
            licensesForPracticingProfessionalPackage.ActiveLicensesCount +=
              res.responseData?.activeCertificatesCount;
            licensesForPracticingProfessionalPackage.ExpiredLicensesCount +=
              res.responseData?.expiredCertificatesCount;
          }
        });
    }
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  private async getNoneConfirmedRequests(): Promise<void> {
    const response = await this.esCommonService
      .getUnconfirmedPoppedUpRequest()
      .toPromise();

    this.noneConfirmedRequests = this.mapConfirmationRequests(response);
  }

  private mapConfirmationRequests(
    response: EsResponseModel<EsUnconfirmedPoppedUpRequestDto>
  ): EsUnconfirmedPoppedUpRequest {
    const data = response?.Data;

    if (data) {
      return {
        confirmationType: data.ConfirmationType,
        id: data.Id,
        participantID: data.ParticipantID,
        procedureInstanceID: data.ProcedureInstanceID,
        url: data.Url,
      };
    }

    return null;
  }

  public get confirmationRequestAlert(): AlertOptions {
    if (this.noneConfirmedRequests) {
      const key =
        this.noneConfirmedRequests.confirmationType ==
        ConfirmationRequestCodeEnum._4Hours
          ? 'trConfirmationRequest4HoursMessage'
          : 'trConfirmationRequest48HoursMessage';

      const text = this.translateService.instant(key);

      return {
        display: true,
        code: '',
        text,
      };
    }

    return null;
  }

  public async confirmRequest() {
    if (this.noneConfirmedRequests) {
      const response = await this.esCommonService
        .confirmOnCallRequestPopUp(this.noneConfirmedRequests.id)
        .toPromise();

      if (response.Data == true) {
        this.noneConfirmedRequests = null;

        await this.getNoneConfirmedRequests();
      }
    }
  }

  ngAfterViewInit() {}

  public get registeredUserType() {
    return this.utilService.getUserProfile().registeredUserType;
  }

  differenceBetweenLastRequestDateAndCurrentDateInHours() {
    let lastRequestDate: any;
    let currentDate: Date;
    if (!this.dashBoardDetailsObject) {
      return null;
    }

    let diffDays = 0;
    lastRequestDate = moment(
      this.dashBoardDetailsObject.Data.LastRequestDate,
      'YYYY-MM-DDThh:mm:ssZ'
    ).toDate();
    lastRequestDate.setHours(0, 0, 0, 0);
    currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const diff = Math.abs(currentDate.getTime() - lastRequestDate.getTime());
    if (diff != 0) {
      diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    }

    return diffDays;
  }

  viewRequest(requestId: string) {
    const url = `/generic-service-view/animal-care-view?serviceRequestTransactionId=${requestId}`;

    window.open(url, '_blank');
  }

  getStatusCodeColor(statusCode: string): string {
    const color = Object(RequestStatusCodesColors)[statusCode];
    return color;
  }
}
