import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationBaseOptions } from './ValidationBaseOptions';

export function moreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) >=
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) <=
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function moreThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) >
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) <
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function optionalMoreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid =
      control.value == null ||
      new Date(new Date(control.value).toDateString()) >=
        new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}
