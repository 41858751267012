import { Lookup } from 'src/app/core/models/common/Lookup';

export class PurposeCodesData {
  static data: Lookup[] = [
    {
      id: 1,
      code: 'T',
      nameEn: 'Commercial',
      nameAr: 'غرض تجاري',
    },
    {
      id: 2,
      code: 'Z',
      nameEn: 'Zoo',
      nameAr: 'حدائق حيوان',
    },
    {
      id: 3,
      code: 'G',
      nameEn: 'Botanical garden',
      nameAr: 'حدائق نباتية',
    },
    {
      id: 4,
      code: 'Q',
      nameEn: 'Circus or travelling exhibition',
      nameAr: 'السيرك والمعارض المتنقلة',
    },
    {
      id: 5,
      code: 'S',
      nameEn: 'Scientific',
      nameAr: 'غرض علمي',
    },
    {
      id: 6,
      code: 'H',
      nameEn: 'Hunting trophy',
      nameAr: 'جوائز الصيد',
    },
    {
      id: 7,
      code: 'P',
      nameEn: 'Personal',
      nameAr: 'غرض شخصي',
    },
    {
      id: 8,
      code: 'M',
      nameEn: 'Medical (biomedical research)',
      nameAr: 'غرض طبي (الطب الحيوي)',
    },
    {
      id: 9,
      code: 'E',
      nameEn: 'Educational',
      nameAr: 'غرض تعليمي',
    },
    {
      id: 10,
      code: 'N',
      nameEn: 'Reintroduction or introduction into the wild',
      nameAr: 'الإطلاق في البرية',
    },
    {
      id: 11,
      code: 'B',
      nameEn: 'Breeding in captivity or artificial propagation',
      nameAr: 'الإكثار في الأسر أو الإكثار الاصطناعي',
    },
    {
      id: 12,
      code: 'L',
      nameEn: 'Law enforcement / judicial / forensic',
      nameAr: 'إنفاذ القانون / القضاء / الطب الشرعي',
    },
  ];
}
