import { BehaviorSubject } from 'rxjs';
import { WorkflowStatefulStep } from '../../../shared/base/steps/WorkflowStatefulStep';

export abstract class BaseStateService {
  protected serviceKey: string;

  protected _stepInitialized$ = new BehaviorSubject<WorkflowStatefulStep>(null);
  public stepInitialized$ = this._stepInitialized$.asObservable();

  public initialize(serviceKey: string) {
    this.serviceKey = serviceKey;

    this.emitStepInitialized();
  }

  protected abstract emitStepInitialized(): void;
}
