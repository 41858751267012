<form
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
  appInputComponentsInvalidScroller
>
  <div class="row">
    <div class="col-12">
      <legend
        class="col-form-label"
        [innerText]="'The Consignment Data' | translate"
      ></legend>
    </div>
  </div>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol" *ngIf="formGroup.controls.class">
      <bs-ng-dropdown
        formControlName="class"
        identifier="class"
        key="trCitesClassFld"
        name="class"
        [items]="lookups.classes"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        (change)="onClassChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.fullCol" *ngIf="formGroup.controls.appendix">
      <bs-ng-dropdown
        formControlName="appendix"
        identifier="appendix"
        key="trRegisterAppendixNoFld"
        name="appendix"
        [items]="lookups.appendixes"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        (change)="onAppendixChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.fullCol" *ngIf="formGroup.controls.type">
      <bs-ng-dropdown
        formControlName="type"
        identifier="type"
        key="trRegisterTypeFld"
        name="type"
        [items]="lookups.INRTypes"
        bindLabel="scientificName"
        [breakSpaces]="true"
        (change)="onTypeChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
  </div>

  <div class="row" *ngIf="formGroup.controls.type?.valid">
    <div dsCol [colOptions]="fo.fullCol" *ngIf="formGroup.controls.isRelated">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="isRelated"
          class="custom-control-input"
          formControlName="isRelated"
          (change)="onIsRelatedChange($event)"
        />
        <label class="custom-control-label" for="isRelated">
          <span
            [innerHTML]="'trCitesIsRelatedFld.labelText' | translate"
          ></span>
        </label>
      </div>
    </div>
    <div dsCol [colOptions]="fo.fullCol" *ngIf="formGroup.controls.relatedType">
      <bs-ng-dropdown
        formControlName="relatedType"
        identifier="relatedType"
        key="trCitesRelatedTypeFld"
        name="relatedType"
        [items]="lookups.relatedTypes"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trRegisterCommonNameFld"
        [valueText]="formGroup.controls.type.value | lookup : lang"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </app-bs-display>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-dropdown
        formControlName="source"
        identifier="source"
        key="trRegisterSourceFld"
        name="source"
        [items]="sources"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        (change)="onSourceChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
        infoDialogId="CitesSourceCodes"
      >
      </bs-ng-dropdown>
    </div>
    <div
      dsCol
      [colOptions]="fo.fullCol"
      *ngIf="formGroup.controls.sourceReason"
    >
      <bs-ng-textarea
        formControlName="sourceReason"
        identifier="sourceReason"
        key="trRegisterSourceReasonFld"
        name="sourceReason"
        maxLength="500"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-textarea>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="accompanyingCertificateNo"
        identifier="accompanyingCertificateNo"
        key="trCitesAccompanyingCertificateNoFld"
        name="accompanyingCertificateNo"
        maxLength="200"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-date
        formControlName="accompanyingCertificateDate"
        identifier="accompanyingCertificateDate"
        key="trCitesAccompanyingCertificateDateFld"
        name="accompanyingCertificateDate"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-date>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-dropdown
        formControlName="originCountry"
        identifier="originCountry"
        key="trRegisterOriginCountryFld"
        name="originCountry"
        [items]="lookups.originCountries"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        (change)="onOriginCountryChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="exportingCertificateNo"
        identifier="exportingCertificateNo"
        key="trCitesOriginCertificateNoFld"
        name="exportingCertificateNo"
        maxLength="200"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-text>
    </div>
    <div class="col-sm-12" *ngIf="disclaimers && disclaimers.length > 0">
      <app-es-service-disclaimers [alerts]="disclaimers">
      </app-es-service-disclaimers>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-date
        formControlName="exportingCertificateDate"
        identifier="exportingCertificateDate"
        key="trCitesOriginCertificateDateFld"
        name="exportingCertificateDate"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-date>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-dropdown
        formControlName="lastReExportCountry"
        identifier="lastReExportCountry"
        key="trCitesLastReExportCountryFld"
        name="lastReExportCountry"
        [items]="lookups.originCountries"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="lastReExportCertificateNumber"
        identifier="lastReExportCertificateNumber"
        key="trCitesLastReExportCertificateNumberFld"
        name="lastReExportCertificateNumber"
        maxLength="200"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-date
        formControlName="lastReExportCertificateDate"
        identifier="lastReExportCertificateDate"
        key="trCitesLastReExportCertificateDateFld"
        name="lastReExportCertificateDate"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-date>
    </div>
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-textarea
        formControlName="specimenDescription"
        identifier="specimenDescription"
        key="trRegisterSpecimenDescriptionFld"
        name="specimenDescription"
        maxLength="500"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-textarea>
    </div>
    <!-- Identifiable -->
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.ringNumber">
      <bs-ng-text
        formControlName="ringNumber"
        identifier="ringNumber"
        key="trRegisterRingNumberFld"
        name="ringNumber"
        (blur)="onRingNumberChange($event)"
        maxLength="200"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.chipNumber">
      <bs-ng-text
        formControlName="chipNumber"
        identifier="chipNumber"
        key="trRegisterChipNumberFld"
        name="chipNumber"
        (blur)="onChipNumberChange($event)"
        maxLength="200"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.gender">
      <bs-ng-dropdown
        formControlName="gender"
        identifier="gender"
        key="trRegisterGenderFld"
        name="gender"
        [items]="lookups.genders"
        [bindLabel]="defaultLookupDisplayProperty"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.birthdate">
      <bs-ng-date
        formControlName="birthdate"
        identifier="birthdate"
        key="trCitesBirthdateFld"
        name="birthdate"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-date>
    </div>
    <!-- non-identifiable -->
    <div
      dsCol
      [colOptions]="fo.halfCol"
      *ngIf="formGroup.controls.typeDescription"
    >
      <bs-ng-dropdown
        formControlName="typeDescription"
        identifier="typeDescription"
        key="trRegisterTypeDescriptionFld"
        name="typeDescription"
        [items]="types"
        [bindLabel]="defaultLookupDisplayProperty"
        [breakSpaces]="true"
        (change)="onTypeDescriptionChange($event)"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.unit">
      <bs-ng-dropdown
        formControlName="unit"
        identifier="unit"
        key="trRegisterUnitFld"
        name="unit"
        [items]="lookups.packageUnits"
        [bindLabel]="defaultLookupDisplayProperty"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.quantity">
      <bs-ng-number
        formControlName="quantity"
        identifier="quantity"
        key="trRegisterQuantityFld"
        name="quantity"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </bs-ng-number>
    </div>
    <div
      dsCol
      [colOptions]="fo.halfCol"
      *ngIf="formGroup.controls.actualQuantity"
    >
      <app-bs-display
        key="trCitesAvailableQuantityFld"
        valueText="{{
          formGroup.controls.actualQuantity.value > 0
            ? formGroup.controls.actualQuantity.value
            : ('trCitesQuantityConsumedFld.labelText' | translate)
        }}"
        [templateOptions]="fo.inputsTemplateOptions"
      >
      </app-bs-display>
    </div>
  </div>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <app-es-main-item-form-buttons
        [mode]="mode"
        (action)="handleItemFormAction($event)"
      >
      </app-es-main-item-form-buttons>
    </div>
  </div>
</form>

<app-cites-codes
  dialogId="CitesSourceCodes"
  titleKey="CitesSourceCodesDescription"
  [items]="sourceCodes"
>
</app-cites-codes>
