import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  Injector,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { FabComponent } from './shared/layout/fab/fab.component';
import { StickyMenuComponent } from './shared/layout/sticky-menu/sticky-menu.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor.service';
import { LoaderService } from './services/loader.service';
import { HealthCheckComponent } from './shared/health-check/health-check.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared-module/shared-module.module';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from './shared/authentication/token.interceptor';
import { LaunchingDigitalServicesComponent } from './services/launching-digital-services/launching-digital-services.component';
import { environment } from 'src/environments/environment';
import { OutstandingPaymentComponent } from './shared/outstanding-payment/outstanding-payment.component';
import { appProfileInitializer } from './shared/initializer/app-initializer.profile.service';
import { UtilService } from './core/utilities/util.service';
import { GetProfileService } from './core/api-services/customer/get-profile.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { appEmployeeInitializer } from './shared/initializer/app-initializer.employee.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SettingsService } from './shared/settings/settings.service';
import { SettingsLocalStorageService } from './shared/settings/settings-local-storage.service';
import { HomeModule } from './dashboard/home/home.module';
import { HeaderMenuComponent } from './shared/layout/header-menu/header-menu.component';
import { ProfileComponent } from './shared/layout/profile/profile.component';
import { ThemeService } from './core/utilities/theme.service';

export let AppInjector: Injector;
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderMenuComponent,
    FooterComponent,
    FabComponent,
    StickyMenuComponent,
    ProfileComponent,
    // StatisticsComponent,
    NotificationComponent,
    HealthCheckComponent,
    LaunchingDigitalServicesComponent,
    OutstandingPaymentComponent,
    ErrorPageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    HttpClientModule,
    CommonModule,
    HomeModule,
    ToastrModule.forRoot({ timeOut: 13000 }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorkerModule,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxMaskModule.forRoot(),
  ],

  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    CookieService,

    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appProfileInitializer,
      multi: true,
      deps: [UtilService, GetProfileService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appEmployeeInitializer,
      multi: true,
      deps: [UtilService, GetProfileService],
    },
    BnNgIdleService,
    SettingsService,
    SettingsLocalStorageService,
    ThemeService,
  ],

  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

export function httpTranslateLoader(http: HttpClient) {
  //return new TranslateHttpLoader(http);
  //set a date timestamp for my caching hash. Saves me from having to manually set a cache hash for every build
  //return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());

  //provide a cachebusting query parameter as suffix
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cacheBuster=' + environment.cacheBusterHash
  );
}
