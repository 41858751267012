<div *ngFor="let file of files">
  <div class="d-flex align-items-center flex-gap-1">
    <span>
      {{ isArabic ? file.nameAr : file.nameEn }}
    </span>
    <a class="ds-btn-icon" [href]="link(file)">
      <i class="fi-download fi-large mx-2"></i>
    </a>
  </div>
</div>
