import { Component, Injector, Input } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'bs-ng-switch',
  templateUrl: './bs-ng-switch.component.html',
  providers: [MakeProvider(BsNgSwitchComponent)],
})
export class BsNgSwitchComponent extends BsNgInputBaseComponent {
  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }
}
