import { MapsAPILoader } from '@agm/core';
import {} from '@angular/google-maps';
import {
  Component,
  ElementRef,
  Injector,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { MapLocation } from '../../../../../core/models/shared/MapLocation';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';

@Component({
  selector: 'bs-ng-map',
  templateUrl: './bs-ng-map.component.html',
  providers: [MakeProvider(BsNgMapComponent)],
})
export class BsNgMapComponent extends BsNgInputBaseComponent {
  @Input()
  location: MapLocation = null;

  @Input()
  loadUserLocation: boolean = false;

  @Input()
  markerDraggable = true;

  @ViewChild('search')
  searchElementRef: ElementRef | null = null;

  zoom: number;
  radius: number;
  circleColor: string;
  private geoCoder: any;

  constructor(
    translate: TranslateService,
    injector: Injector,
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    super(translate, injector);

    if (this.location) {
      ///keep it
    } else {
      ///initialize from default
      this.location = new MapLocation();
      this.location.latitude = environment.googleMapConfigs.defaultLatitude;
      this.location.longitude = environment.googleMapConfigs.defaultLongitude;
      this.location.address = environment.googleMapConfigs.defaultAddress;
    }

    this.zoom = environment.googleMapConfigs.defaultZoom;
    this.radius = environment.googleMapConfigs.defaultRadius;
    this.circleColor = environment.googleMapConfigs.defaultCircleColor;
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);

    this.mapsApiLoader.load().then(() => {
      if (this.loadUserLocation) {
        this.setCurrentLocation();
      } else if (this.location) {
        this.setLocation(
          this.location.latitude,
          this.location.longitude,
          this.location.address
        );
      }

      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef?.nativeElement
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.setLocation(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );
        });
      });

      this.value = this.location;
    });
  }

  markerDragEnd($event: any) {
    this.setLocation($event.coords.lat, $event.coords.lng);
  }

  private setLocation(lat: number, lng: number, address?: string) {
    this.location.latitude = lat;
    this.location.longitude = lng;
    if (address) {
      this.location.address = address;
    } else {
      this.getAddress(this.location.latitude, this.location.longitude);
    }
    this.value = this.location;
  }

  async getAddress(latitude: number, longitude: number) {
    if (this.geoCoder) {
      await this.geoCoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (results: any, status: any) => {
          if (status === 'OK') {
            if (results[0]) {
              this.location.address = results[0].formatted_address;
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }
      );
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) =>
        this.setLocation(position.coords.latitude, position.coords.longitude)
      );
    }
  }

  get touched() {
    return this.ngControl.control.touched;
  }

  addressChange() {
    if (this.location.address == '') {
      this.value = null;
    }
  }
}
