import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ds-activity-files',
  templateUrl: './ds-activity-files.component.html',
})
export class DsActivityFiles extends BaseComponent {
  @Input()
  files: DsSharedFile[];

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  public link(file: DsSharedFile): string {
    return `${environment.sharedFilesServiceURL}${file.path}`;
  }
}

export class DsSharedFile {
  path: string;
  nameAr: string;
  nameEn: string;
  extension: string;
}
