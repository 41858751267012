import { Injectable, Inject } from '@angular/core';
import { ServiceConfigurationLoadedStateManager } from './service-configuration-loaded.service';
import {
  ServiceRequestLoadedStateManager,
  DraftServiceRequestLoadedStateManager,
} from './service-request-loaded.service';
import { ApplicantStateService } from '../ApplicantState.service';
import { UtilService } from 'src/app/core/utilities/util.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ServiceDetailByDigitalNumberService } from 'src/app/core/api-services/portal/service-detail-by-digital-number.service';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';

@Injectable()
export class DispatchersFacadeService {
  constructor(
    @Inject('serviceConfigurationLoadedStateManager')
    public serviceConfigurationLoaded: ServiceConfigurationLoadedStateManager,
    @Inject('serviceRequestLoadedStateManager')
    public serviceRequestLoaded: ServiceRequestLoadedStateManager,
    @Inject('draftServiceRequestLoadedStateManager')
    public draftRequestLoaded: DraftServiceRequestLoadedStateManager
  ) {}
}

@Injectable()
export class NativesFacadeService {
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    @Inject(DOCUMENT) public document: Document,
    public platform: Platform
  ) {}
}

@Injectable()
export class UtilsFacadeService {
  constructor(
    public utilService: UtilService,
    public translateService: TranslateService,
    public toastr: ToastrService
  ) {}
}

@Injectable()
export class ApisFacadeService {
  constructor(
    public metadataService: ServiceDetailByDigitalNumberService,
    public commonApiService: EsCommonService
  ) {}
}

@Injectable()
export class StatesFacadeService {
  constructor(
    @Inject('applicantStateService')
    public applicantStateService: ApplicantStateService
  ) {}
}
