import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  GeneratingCustomerPulseRequestObject,
  GeneratingCustomerPulseResponseObject,
} from '../../models/common/GeneratingCustomerPulseObject';
import { ResponseModel } from '../../models/shared/CommonResponseDTO';

@Injectable({
  providedIn: 'root',
})
export class GeneratingCustomerPulseTokenService {
  private REST_API_SERVER =
    environment.DigitalServicesHostURL +
    'CustomerPulse/' +
    'GeneratingCustomerPulseToken';

  constructor(private httpClient: HttpClient) {}

  public generatingCustomerPulseToken(
    generatingCustomerPulseRequestObject: GeneratingCustomerPulseRequestObject
  ): Observable<ResponseModel<GeneratingCustomerPulseResponseObject>> {
    return this.httpClient
      .post<ResponseModel<GeneratingCustomerPulseResponseObject>>(
        this.REST_API_SERVER,
        generatingCustomerPulseRequestObject
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error(
        '[GeneratingCustomerPulseTokenService] Client Side Error',
        errorResponse.error.message
      );
    } else {
      console.error(
        '[GeneratingCustomerPulseTokenService] Server Side Error',
        errorResponse.error.message
      );
    }

    return throwError(
      'There is a problem with the service. we are notified & working on it. please try again later'
    );
  }
}
