import { SecondPaymentFees } from '../accreditation/accredit-vet-product/AccreditVetProductServiceRequestDto';
import { EsApplicantDto } from './EsApplicantDto';
import { EsAttachmentDetailDto } from './EsAttachmentDetailDto';
import { EsCommentDto } from './EsCommentDto';
import { EsReceiptDto } from './EsReceiptDto';
import { LookupDto } from './LookupDto';
import { OutPutsDto } from './OutPutsDto';
import { ProcedureInstanceObjectDto } from './ProcedureInstanceObjectDto';

export interface ServiceRequestDto<
  TRequestData //extends ExportRequestDataDto<RequestDetailDto>
> {
  RequestData: TRequestData;
  ProcedureId: number;
  ServiceObject: null;
  ProcedureInstanceObject: ProcedureInstanceObjectDto;
  Applicant: EsApplicantDto;
  Remarks: string;
  AdditionalData: null;
  AttachmentDetails: EsAttachmentDetailDto[];
  Comments: EsCommentDto[];
  Receipts: EsReceiptDto[];
  OutPuts: OutPutsDto[];

  RequestAvailabilityStatus: RequestAvailabilityEnum;
  IsValidForRelease: boolean;
  ReleaseServiceURl: string;

  SecondPaymentFees: SecondPaymentFees;
  InitiatorActions: LookupDto[];
}

export enum RequestAvailabilityEnum {
  Locked = 'Locked',
  Available = 'Available',
  HasPendingPayment = 'HasPayment',
  HasPendingAction = 'HasPendingAction',
}
