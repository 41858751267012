import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Injector,
  ViewChild,
  ElementRef,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { TranslateService } from '@ngx-translate/core';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';
import { Subject } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bs-ng-lookup',
  templateUrl: './bs-ng-lookup.component.html',
  providers: [MakeProvider(BsNgLookupComponent)],
  styleUrls: ['./bs-ng-lookup.component.css'],
})
export class BsNgLookupComponent
  extends BsNgInputBaseComponent
  implements OnInit, AfterContentInit, OnChanges
{
  @Input() options: any[] = [];
  @Input('option-text') optionText = 'text';
  @Input('option-value') optionValue = 'value';

  @ViewChild('textFilterInputRef')
  textFilterInput: ElementRef;

  @Output()
  search = new EventEmitter();
  textFilterChange = new Subject<string>();

  @Output()
  getSelected = new EventEmitter();

  @Input()
  opened = false;

  @Input()
  selected: any;
  showFilter = true;

  @Input()
  textResolver: (option: any) => void;

  @Input()
  showClearSelection = true;

  @Input()
  nullOption = {};

  clearSelectionText: any;

  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  assignTranslations(trs: any) {
    super.assignTranslations(trs);

    this.clearSelectionText = trs.clearSelectionText;
  }

  ngOnInit() {
    if (this.key) {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);

    this.registerOnChange((v) => {
      for (const option of this.options || []) {
        if (option[this.optionValue] == v) {
          this.select(option);
        }
      }
    });
  }

  ngAfterContentInit() {
    const MIN_CHARACHTERS_TO_START_SEARCH = 3;
    const DEBOUNCE_TO_SEARCH_MS = 400;

    this.textFilterChange
      .pipe(
        filter(
          (t) => t.length >= MIN_CHARACHTERS_TO_START_SEARCH || t.length === 0
        )
      )
      .pipe(debounceTime(DEBOUNCE_TO_SEARCH_MS))
      .pipe(distinctUntilChanged())
      .subscribe((query) => {
        this.search.emit({ searchText: query });
      });
  }

  select(option: any) {
    this.selected = option;
    this.writeValue(option[this.optionValue]);
    this.opened = false;
    this.showFilter = false;
    this.getSelected.emit({ selected: option });
  }

  selectedChange($event: any) {}

  onTextFilterChanged($event: any) {
    this.opened = $event.target.value && true;
    this.textFilterChange.next($event.target.value);
  }

  selectedItemClicked() {
    this.showFilter = true;
    this.opened = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this was commented as it was hiding the dropdown once I seach by any text!
    // if (changes.options) {
    //   const option = this.options?.filter(
    //     (i) => i[this.optionValue] == this.value
    //   )[0];
    //   this.select(option || {});
    // }
  }

  clearSelection() {
    this.select(this.nullOption || {});
    this.textFilterInput.nativeElement.value = '';
  }
}
