import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SettingsService {
  private _size$: BehaviorSubject<SettingsSizeEmitterData>;
  private _isSupportResizing = false;

  private _formsTemplate$: BehaviorSubject<FormTemplateEmitterData>;
  private _isSupportFormTemplates = false;

  private _isFocusMode$: BehaviorSubject<FocusModeEmitterData>;

  public get size$(): BehaviorSubject<SettingsSizeEmitterData> {
    return this._size$;
  }

  public get formsTemplate$(): BehaviorSubject<FormTemplateEmitterData> {
    return this._formsTemplate$;
  }

  public get focusMode$(): Observable<FocusModeEmitterData> {
    return this._isFocusMode$;
  }

  public get isSupportResizing(): boolean {
    return this._isSupportResizing;
  }

  public get isSupportFormTemplates(): boolean {
    return this._isSupportFormTemplates;
  }

  constructor() {
    this._size$ = new BehaviorSubject<SettingsSizeEmitterData>({
      size: SettingsSizeEnum.Full,
    });

    this._formsTemplate$ = new BehaviorSubject<FormTemplateEmitterData>({
      template: FormTemplateEnum.Default,
    });

    this._isFocusMode$ = new BehaviorSubject<FocusModeEmitterData>({
      isFocusMode: false,
    });
  }

  public changeSizeToFull(): void {
    this._size$.next({
      size: SettingsSizeEnum.Full,
    });
  }

  public changeSizeToNormal(): void {
    this._size$.next({
      size: SettingsSizeEnum.Normal,
    });
  }

  public changeFormTemplateToDefault(): void {
    this._formsTemplate$.next({
      template: FormTemplateEnum.Default,
    });
  }

  public changeFormTemplateToHorizontal(): void {
    this._formsTemplate$.next({
      template: FormTemplateEnum.Horizontal,
    });
  }

  public setSupportResizing(supportResizing: boolean): void {
    this._isSupportResizing = supportResizing;
  }

  public setSupportFormsTemplates(supportTemplateForms: boolean): void {
    this._isSupportFormTemplates = supportTemplateForms;
  }

  public enterToFocusMode() {
    this._isFocusMode$.next({
      isFocusMode: true,
    });
  }

  public exitFocusMode() {
    this._isFocusMode$.next({
      isFocusMode: false,
    });
  }
}

export class SettingsSizeEmitterData {
  size: SettingsSizeEnum;
}

export class FocusModeEmitterData {
  isFocusMode: boolean;
}

export enum SettingsSizeEnum {
  Full = 'Full',
  Normal = 'Normal',
}

export class FormTemplateEmitterData {
  template: FormTemplateEnum;
}

export enum FormTemplateEnum {
  Default = 'Default',
  Horizontal = 'Horizontal',
}
