import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';

@Injectable()
export class ServiceConfigurationLoadedStateManager {
  private subject = new Subject<EsServiceRequestConfiguration>();
  public observable$ = this.subject.asObservable();

  public next(result: EsServiceRequestConfiguration) {
    this.subject.next(result);
  }
}
