<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-6 text-left">
        <strong>
          <a
            [href]="workItem.frontOfficeViewPageUrl"
            [innerText]="
              ('DashboardRequestNo' | translate) +
              '#' +
              workItem.serviceRequestTransactionNumber
            "
            class="text-primary"
          >
          </a
        ></strong>
      </div>
      <div
        class="col-sm-6 text-right text-secondary"
        [innerText]="
          workItem.serviceRequestCreationDate | date : 'EEEE, MMMM d, y'
        "
      ></div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-5 col-md-6">
        <p
          class="text-secondary"
          [innerText]="
            isEnglish
              ? workItem.serviceNameEn + '-' + workItem.requestTypeNameEn
              : workItem.serviceNameAr + '-' + workItem.requestTypeNameAr
          "
        ></p>
      </div>

      <div class="col-lg-2 col-md-6" style="text-align: start">
        <strong
          *ngIf="
            workItem.workflowStatusCode &&
            workItem.workflowStatusCode !=
              WorkflowStepStatusCodesEnum.NewRequest
          "
          [ngStyle]="{
            color: workItem.workflowStatusColorCode
          }"
          class="bold position"
          [innerText]="
            isEnglish
              ? workItem.workflowStatusNameEn
              : workItem.workflowStatusNameAr
          "
        ></strong>
        <strong
          *ngIf="
            workItem.workflowStatusCode ==
            WorkflowStepStatusCodesEnum.NewRequest
          "
          [ngStyle]="{
            color: workItem.workflowStatusColorCode
          }"
          class="bold position"
          [innerText]="'DraftStatus' | translate"
        ></strong>

        <small
          *ngIf="
            workItem.workflowStatusCode ==
            WorkflowStepStatusCodesEnum.InitialPayment
          "
          class="text-danger mb-2 d-block"
          [innerText]="'WaitingForInitialPayment' | translate"
        ></small>
      </div>

      <div class="col-lg-5 col-md-12 my_custom_tooltip">
        <div class="row request-card-actions justify-content-md-end">
          <ng-container
            *ngIf="
              workItem.isDraftRecord == true &&
                workItem.IsEservicesRequest == false;
              else notDraft
            "
          >
            <ng-container *ngFor="let action of workItem.requestActions">
              <a
                (click)="cancelDraftRequest.emit(workItem)"
                *ngIf="action.actionPageURL == 'CancelRequest'; else notCancel"
                class="col col-3 request-action text-center cursor-pointer textShortToolTipShow cancel_reqst"
                ngbTooltip="{{ 'HomePageCancelRequest' | translate }}"
              >
                <span
                  class="icon-cancel_license d-block"
                  style="font-size: 28px"
                ></span>
                {{ 'HomePageCancelRequest' | translate }}
              </a>
              <ng-template #notCancel>
                <a
                  (click)="goToPage.emit(action)"
                  class="col col-3 request-action text-center cursor-pointer textShortToolTipShow cancel_reqst"
                  ngbTooltip="{{ 'HomePageTakeAction' | translate }}"
                >
                  <span
                    class="icon-continuew_arrow d-block"
                    style="font-size: 28px"
                  ></span>
                  {{ 'HomePageTakeAction' | translate }}
                </a>
              </ng-template>
            </ng-container>
          </ng-container>

          <ng-template #notDraft>
            <ng-container *ngFor="let action of workItem.requestActions">
              <a
                (click)="cancelRequest.emit({workItem,action})"
                *ngIf="
                  action.actionPageURL == 'CancelRequest' ||
                    action.actionPageURL == 'CancelRequestEs';
                  else notCancel
                "
                class="col col-3 request-action text-center cursor-pointer textShortToolTipShow cancel_reqst"
                [ngbTooltip]="isEnglish ? action.nameEn : action.nameAr"
              >
                <span
                  [ngClass]="action.frontOfficeIconName"
                  class="d-block"
                  style="font-size: 28px"
                ></span>
                {{ isEnglish ? action.nameEn : action.nameAr }}
              </a>
              <ng-template #notCancel>
                <a
                  (click)="goToPage.emit(action)"
                  class="col col-3 request-action text-center cursor-pointer textShortToolTipShow cancel_reqst"
                  [ngbTooltip]="isEnglish ? action.nameEn : action.nameAr"
                >
                  <span
                    [ngClass]="action.frontOfficeIconName"
                    class="d-block"
                    style="font-size: 28px"
                  ></span>
                  {{ isEnglish ? action.nameEn : action.nameAr }}
                </a>
              </ng-template>
            </ng-container>
          </ng-template>

          <a
            *ngIf="workItem && workItem.hasCertificate"
            data-toggle="modal"
            data-target="#modal_viewCertificate"
            (click)="selectCertificate.emit(workItem)"
            class="col col-3 request-action text-center cursor-pointer textShortToolTipShow"
            ngbTooltip="{{ 'HomeDownload' | translate }}"
          >
            <span
              class="icon-get_app-24px d-block"
              style="font-size: 28px"
            ></span>
            {{ 'HomeDownload' | translate }}
          </a>

          <a
            style="display: none"
            class="cursor-pointer textShortToolTipShow"
            data-toggle="modal"
            ngbTooltip="Audit Trail"
            data-target="#myModal-2"
          >
            <img
              src="assets/Images/Icons/assignment_turned_in-24px.png"
              class="col text-center cursor-pointer"
          /></a>

          <a
            *ngIf="workItem.hasPayment"
            [routerLink]="['/view-paid-payment-requests']"
            [queryParams]="{
              serviceRequestTransactionId: workItem.serviceRequestTransactionId
            }"
            target="_blank"
            class="col col-3 request-action text-center cursor-pointer textShortToolTipShow"
            ngbTooltip="{{ 'HomePrintReceipt' | translate }}"
          >
            <span class="icon-receipt d-block" style="font-size: 28px"></span>
            {{ 'HomePrintReceipt' | translate }}
          </a>

          <a
            [href]="workItem.frontOfficeViewPageUrl"
            target="_blank"
            class="col col-3 request-action text-center cursor-pointer textShortToolTipShow"
            ngbTooltip="{{ 'HomeView' | translate }}"
            *ngIf="
              (workItem.IsEservicesRequest == true &&
                workItem.workflowStatusCode ==
                  WorkflowStepStatusCodesEnum.Draft) == false
            "
          >
            <span
              class="icon-visibility-24px-1 d-block"
              style="font-size: 28px"
            ></span>
            {{ 'HomeView' | translate }}
          </a>

          <a
            (click)="onClickCommunicationMessage.emit(workItem)"
            data-toggle="modal"
            data-target="#notificationMessageModalDialog"
            class="col col-3 request-action text-center cursor-pointer textShortToolTipShow"
            ngbTooltip="{{ 'ViewSendMessages' | translate }}"
            *ngIf="workItem.IsEservicesRequest == false"
          >
            <span
              class="icon-send-and-view d-block"
              style="font-size: 28px"
            ></span>
            {{ 'ViewSendMessages' | translate }}
          </a>
          <a
            (click)="onEsClickCommunicationMessage.emit(workItem)"
            data-toggle="modal"
            data-target="#esCommentsModalDialog"
            class="col col-3 request-action text-center cursor-pointer textShortToolTipShow"
            ngbTooltip="{{ 'ViewSendMessages' | translate }}"
            *ngIf="workItem.IsEservicesRequest"
          >
            <span
              class="icon-send-and-view d-block"
              style="font-size: 28px"
            ></span>
            {{ 'ViewSendMessages' | translate }}
          </a>
        </div>

        <div class="dropdown" style="display: none">
          <button class="dropbtn cursor-pointer">
            <img src="assets/Images/Icons/more-icon.png" />
          </button>
          <div class="dropdown-content text-left cursor-pointer">
            <a>{{ 'Edit' | translate }}</a>
            <a>{{ 'View' | translate }}</a>
            <a class="color-red">{{ 'Delete' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col text-left">
    <ng-container #requestCardExtra></ng-container>
  </div>
</div>
