import { Pipe, PipeTransform } from '@angular/core';
import { FavoriteServiceHybrid } from '../../../core/api-services/shared/services/ServicesListDataService';

@Pipe({
  name: 'orderByIsFavorite',
})
export class OrderByIsFavoritePipe implements PipeTransform {
  public transform(value: FavoriteServiceHybrid[]) {
    return (value || []).sort(
      (a, b) => Number(b.isFavorite) - Number(a.isFavorite)
    );
  }
}
