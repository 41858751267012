import { Component, Injector, Input } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { ValidationBaseOptions } from '../../../validators/ValidationBaseOptions';

@Component({
  selector: 'bs-ng-date',
  templateUrl: './bs-ng-date.component.html',
  providers: [MakeProvider(BsNgDateComponent)],
})
export class BsNgDateComponent extends BsNgInputBaseComponent {
  @Input() type?: 'date' | 'time' | 'datetime-local' = 'date';

  private readonly _defaultDateMin = '1900-01-01';
  private _min: string;
  @Input()
  public set min(value: string) {
    this._min = value;
  }
  public get min(): string {
    if (this.type == 'date') {
      return this._min || this._defaultDateMin;
    }

    return this._min;
  }

  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  get hasErrors(): boolean {
    if (this.ngControl.control.touched) {
      return this.ngControl.errors && true;
    }

    return false;
  }

  public get isShowErrors(): boolean {
    return (
      this.ngControl.touched &&
      !this.ngControl.valid &&
      !this.ngControl.disabled
    );
  }

  public trackByMessageKey(
    index: number,
    item: { key: string; messageKey: string }
  ): string {
    return item.key;
  }

  public get errors(): any[] {
    if (this.ngControl.touched && this.ngControl.errors) {
      if (this.ngControl.errors.required) {
        ///return only required error
        return [{ key: 'required', messageKey: 'required' + 'Message' }];
      }

      const keys = Object.keys(this.ngControl.errors);

      return keys.map((keyError) => {
        ///nativeValidationError is the old way of handling validation without options
        ///in the case nativeValidationError is false means use the error object as options
        const nativeValidationError = this.ngControl.errors[keyError] === true;

        return nativeValidationError
          ? { key: keyError, messageKey: keyError + 'Message' }
          : this.buildOptions(
              this.ngControl.errors[keyError] as ValidationBaseOptions
            );
      });
    }

    return [];
  }

  protected buildOptions(validationOptions: ValidationBaseOptions): any {
    return {
      key: validationOptions.key,
      messageKey: validationOptions.key + 'Message',
      messageArgs: validationOptions.messageArgs,
    };
  }
}
