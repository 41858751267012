import { Inject, Injectable } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { RequestPurposeTypeEnum } from 'src/app/core/enums/RequestPurposeTypeEnum';
import { EsApplicantIdentityType } from 'src/app/core/models/shared/EsApplicantIdentityType';
import { RequestPurposeLkp } from 'src/app/core/models/shared/Lookups';
import { EsApplicantStateObjectType } from 'src/app/services/shared-workflow-object/EsApplicantStateObjectType';
import { FlowButtons } from '../../../shared/base/FlowButtons';
import { emiratesId as emiratesIdFormat } from '../../../shared/input-components/validators/emiratesIdFormat';
import { EsApplicantDto } from 'src/app/core/models/shared/EsApplicantDto';
import { EsApplicant } from 'src/app/core/models/shared/EsApplicant';
import { WorkflowSteps } from '../../../shared/base/WorkflowSteps';
import { BaseStateService } from './BaseStateService';
import { DispatchersFacadeService } from './state-management/dispatchers-facade.service';

declare let TimeMe: any;

@Injectable()
export class ApplicantStateService extends BaseStateService {
  public ApplicantStateObject: EsApplicantStateObjectType = {
    form: new FormGroup({
      applicant: new FormControl(null, Validators.required),
      id: new FormControl(null),
      participantId: new FormControl(null),
      emiratesId: new FormControl(null, [
        Validators.required,
        emiratesIdFormat(),
      ]),
      passport: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.email),
      preferredLanguage: new FormControl(null, Validators.required),
      selectedRequestPurposeType: new FormControl(null, Validators.required),
      identityType: new FormControl(
        EsApplicantIdentityType.ID,
        Validators.required
      ),
      otp: new FormControl(null),
    }),
    isNewApplicant: false,
    findByEmirateIdResponse: null,
    isValidEmiratesId: false,
    enableStep: true,
    showAddNewApplicantButton: true,
    showEditApplicantButton: true,
    isEditMode: false,
    showRequestPurposeType: false,
    selectedRequestPurposeTypeCode: null,
    url: '',
    flowButtons: this.buildApplicantFlowButtons(),
    requestPurposes: this.mapRequestPurpose(),
  };

  public get requestPurpose(): AbstractControl {
    return this.ApplicantStateObject.form.controls.selectedRequestPurposeType;
  }

  constructor(
    @Inject('dispatcherService')
    private dispatcher: DispatchersFacadeService
  ) {
    super();

    this.subscribeToEvents();
  }

  private subscribeToEvents() {
    this.dispatcher.serviceRequestLoaded.observable$.subscribe((request) => {
      this.setApplicant(request.applicant);
      this.setRequestType(request.requestPurpose);
    });
    this.dispatcher.draftRequestLoaded.observable$.subscribe((request) => {
      this.setPureApplicant(request.applicant, request.requestPurpose);
    });
    this.dispatcher.serviceConfigurationLoaded.observable$.subscribe(
      (config) => {
        if (config.applicants) {
          var applicant = config.applicants[0];
          this.ApplicantStateObject.form.controls.applicant.setValue(applicant);
        }
      }
    );
  }

  private setApplicant(applicant: EsApplicant) {
    this.ApplicantStateObject.form.controls.applicant.setValue(applicant);
  }

  public initialize(serviceKey: string) {
    this.ApplicantStateObject.url =
      '/services/' + serviceKey + '/applicantinformation';

    super.initialize(serviceKey);
  }

  protected emitStepInitialized() {
    this._stepInitialized$.next({
      workflowStep: WorkflowSteps.ApplicantConfiguration,
      state: this.ApplicantStateObject,
    });
  }

  protected buildApplicantFlowButtons(): FlowButtons {
    return {
      next: {
        titleKey: 'Next',
        isVisible: true,
      },
    };
  }

  ///TODO: to unify this method with the one in mapper
  public mapRequestPurpose(): RequestPurposeLkp[] {
    const research: RequestPurposeLkp = {
      id: 1,
      nameAr: 'بحثي',
      nameEn: 'Research',
      code: RequestPurposeTypeEnum.Research,
    };

    const commercial: RequestPurposeLkp = {
      id: 2,
      nameAr: 'تجاري',
      nameEn: 'Commercial',
      code: RequestPurposeTypeEnum.Commercial,
    };

    return [research, commercial];
  }

  public setRequestPurpose(requestPurpose: RequestPurposeLkp) {
    if (requestPurpose) {
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.setValue(
        requestPurpose
      );
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.disable();
    }
  }

  public mapApplicantDto(
    applicant: EsApplicant,
    timeTakenByCustomer: number
  ): EsApplicantDto {
    const result: EsApplicantDto = {
      ID: applicant.id,
      Email: applicant.email,
      IdentityNumber: applicant.identityNumber,
      IdentityType: applicant.identityType,
      Name: applicant.name,
      NameAr: applicant.nameAr,
      Passport: applicant.passport,
      Phone: applicant.phone,
      PreferedLanguage: applicant.preferredLanguage,
      ParticipantID: applicant.participantId,
      TimeTakenByCustomer: timeTakenByCustomer,
    };

    return result;
  }

  private getTimeTakenByCustomer(): number {
    const key = this.serviceKey;

    const result = Math.floor(TimeMe.getTimeOnPageInSeconds(key) || 0);

    return result;
  }

  public startTimer(): void {
    TimeMe.initialize({
      currentPageName: this.serviceKey, // current page
      idleTimeoutInSeconds: 600,
    });

    TimeMe.startTimer();
  }

  public get requestPurposeCode(): RequestPurposeTypeEnum {
    const requestPurpose: RequestPurposeLkp =
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType.value;

    return requestPurpose?.code;
  }

  public createApplicantRequestData(): EsApplicantDto {
    const state = this.ApplicantStateObject;

    const applicant = state.form.get('applicant').value as EsApplicant;

    return this.mapApplicantDto(applicant, this.getTimeTakenByCustomer());
  }

  private setPureApplicant(
    applicant: EsApplicant,
    requestPurpose: RequestPurposeLkp
  ) {
    this.ApplicantStateObject.form.controls.applicant.setValue(applicant);

    if (requestPurpose) {
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.setValue(
        requestPurpose
      );
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.disable();
    }
  }

  private setRequestType(requestPurpose: RequestPurposeLkp) {
    if (requestPurpose) {
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.setValue(
        requestPurpose
      );
      this.ApplicantStateObject.form.controls.selectedRequestPurposeType?.disable();
    }
  }

  public getApplicant(): EsApplicant {
    return this.ApplicantStateObject.form.get('applicant').value;
  }

  public get requestPurposes(): RequestPurposeLkp[] {
    return this.ApplicantStateObject.requestPurposes;
  }

  public disableStep() {
    this.ApplicantStateObject.enableStep = false;
  }
}
