<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'Exporter Details' | translate"
  ></legend>

  <div class="row">
    <div
      dsCol
      [colOptions]="fo.fullCol"
      *ngIf="formGroup.controls.isSelfExported"
    >
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="isSelfExported"
          class="custom-control-input"
          formControlName="isSelfExported"
          [attr.disabled]="disableCountry ? true : null"
          (change)="onSelfExportedChange($event)"
        />
        <label class="custom-control-label" for="isSelfExported">
          <span
            [innerHTML]="'CitesIsSelfExported.labelText' | translate"
          ></span>
        </label>
      </div>
    </div>
    <div dsCol [colOptions]="fo.fullCol">
      <div *ngIf="formGroup.controls.exporterName?.enabled">
        <bs-ng-text
          formControlName="exporterName"
          identifier="exporterName"
          key="trCitesExporterNameFld"
          name="exporterName"
          maxLength="200"
          [templateOptions]="fo.inputsTemplateOptions"
        >
        </bs-ng-text>
      </div>
      <div *ngIf="!formGroup.controls.exporterName?.enabled">
        <app-bs-display
          key="trCitesExporterNameFld"
          [valueText]="exporterDetails.exporterName"
          [templateOptions]="fo.inputsTemplateOptions"
        >
        </app-bs-display>
      </div>
    </div>
    <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.exporterEmail?.enabled">
                <bs-ng-text
                    formControlName="exporterEmail"
                    identifier="exporterEmail"
                    key="trCitesEmailFld"
                    name="exporterEmail"
                    maxLength="200"
                    [templateOptions]="fo.inputsTemplateOptions">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.exporterEmail?.enabled">
                <app-bs-display
                    key="trCitesEmailFld"
                    [valueText]="exporterDetails.exporterEmail"
                    [templateOptions]="fo.inputsTemplateOptions">
                </app-bs-display>
            </div>
        </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.exporterPOBox?.enabled">
                <bs-ng-text
                    formControlName="exporterPOBox"
                    identifier="exporterPOBox"
                    key="trCitesPOBoxFld"
                    name="exporterPOBox"
                    maxLength="200"
                    [templateOptions]="fo.inputsTemplateOptions">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.exporterPOBox?.enabled">
                <app-bs-display
                    key="trCitesPOBoxFld"
                    [valueText]="exporterDetails.exporterPOBox"
                    [templateOptions]="fo.inputsTemplateOptions">
                </app-bs-display>
            </div>
        </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.exporterPhone?.enabled">
                <bs-ng-text
                    formControlName="exporterPhone"
                    identifier="exporterPhone"
                    key="trCitesPhoneFld"
                    name="exporterPhone"
                    maxLength="20"
                    [minlength]="10"
                    [mask]="phoneNumberMask"
                    [templateOptions]="fo.inputsTemplateOptions">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.exporterPhone?.enabled">
                <app-bs-display
                    key="trCitesPhoneFld"
                    [valueText]="exporterDetails.exporterPhone"
                    [templateOptions]="fo.inputsTemplateOptions">
                </app-bs-display>
            </div>
        </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.exporterFax?.enabled">
                <bs-ng-text
                    formControlName="exporterFax"
                    identifier="exporterFax"
                    key="trCitesFaxFld"
                    name="exporterFax"
                    maxLength="20"
                    [mask]="faxMask"
                    [templateOptions]="fo.inputsTemplateOptions">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.exporterFax?.enabled">
                <app-bs-display
                    key="trCitesFaxFld"
                    [valueText]="exporterDetails.exporterFax"
                    [templateOptions]="fo.inputsTemplateOptions">
                </app-bs-display>
            </div>
        </div> -->
    <div dsCol [colOptions]="fo.fullCol">
      <div *ngIf="formGroup.controls.exporterCountry?.enabled">
        <bs-ng-dropdown
          formControlName="exporterCountry"
          identifier="exporterCountry"
          key="trCitesExporterCountryFld"
          name="exporterCountry"
          [items]="lookups.originCountries"
          [bindLabel]="defaultLookupDisplayProperty"
          [disabled]="disableCountry"
          (change)="onCountryChange($event)"
          [templateOptions]="fo.inputsTemplateOptions"
        >
        </bs-ng-dropdown>
      </div>
      <!-- <div *ngIf="!formGroup.controls.exporterCountry?.enabled">
                <div *ngIf="exporterDetails.exporterCountry">
                    <app-bs-display
                        key="trCitesExporterCountryFld"
                        [valueText]="exporterDetails.exporterCountry | lookup:lang"
                        [templateOptions]="fo.inputsTemplateOptions">
                    </app-bs-display>
                </div>
                <div *ngIf="exporterDetails.exporterCountry == null">
                    <app-bs-display
                        key="trCitesExporterCountryFld"
                        [valueText]="'trUAECountryName' | translate"
                        [templateOptions]="fo.inputsTemplateOptions">
                    </app-bs-display>
                </div>
            </div> -->
    </div>
    <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.exporterCityName?.enabled">
                <bs-ng-text
                    formControlName="exporterCityName"
                    identifier="exporterCityName"
                    key="trCitesCityFld"
                    name="exporterCityName"
                    maxLength="50"
                    [templateOptions]="fo.inputsTemplateOptions">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.exporterCityName?.enabled">
                <app-bs-display
                    key="trCitesCityFld"
                    [valueText]="exporterDetails.exporterCityName"
                    [templateOptions]="fo.inputsTemplateOptions">
                </app-bs-display>
            </div>
        </div> -->
    <div dsCol [colOptions]="fo.fullCol">
      <div *ngIf="formGroup.controls.exporterAddress?.enabled">
        <bs-ng-textarea
          formControlName="exporterAddress"
          identifier="exporterAddress"
          key="trCitesExporterAddressFld"
          name="exporterAddress"
          maxLength="250"
          [templateOptions]="fo.inputsTemplateOptions"
        >
        </bs-ng-textarea>
      </div>
      <div *ngIf="!formGroup.controls.exporterAddress?.enabled">
        <app-bs-display
          key="trCitesExporterAddressFld"
          [valueText]="exporterDetails.exporterAddress"
          [templateOptions]="fo.inputsTemplateOptions"
        >
        </app-bs-display>
      </div>
    </div>
  </div>
</form>
